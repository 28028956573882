import React, { Component } from "react";

// to use bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar  from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';

import './header.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {faLanguage} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(
    faLanguage
    // more icon to go
);

class Header extends Component {
    state = {
        isTop: true,
    };
    
    componentDidMount() {
        document.addEventListener('scroll', () => {
          const isTop = window.scrollY < 100;
          if (isTop !== this.state.isTop) {
              this.setState({ isTop })
          }
        });
    }

    render(){
        return(
            <Navbar expand="lg" fixed ="top" id="mainNav" variant="dark" style={this.state.isTop? {paddingLeft: "1rem"}:{backgroundColor: "black", transition: 'ease-in-out 0.925s'}}>
                
                <Navbar.Brand href="/" className = "header-nav mr-auto h1" style={{fontSize: '48px', paddingLeft: '5%'}}>
                    <strong>EziExperience</strong>
                </Navbar.Brand>
                
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <NavDropdown title={<strong>Meet</strong>} id="nav-dropdown" style={{marginLeft: '1em'}}>
                        <NavDropdown.Item href="/aboutUs" id="nav-item">Company</NavDropdown.Item>
                        <NavDropdown.Item href="/aboutUs#team" id="nav-item">Team</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title={<strong>Learn</strong>} id="nav-dropdown" style={{marginLeft: '1em'}}>
                        <NavDropdown.Item href="/homestay" id="nav-item">Online Homestay</NavDropdown.Item>
                        <NavDropdown.Item href="/internship" id="nav-item">Online Internship</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title={<strong>Discover</strong>} id="nav-dropdown" style={{marginLeft: '1em'}}>
                        <NavDropdown.Item href="/blog" id="nav-item">Blog</NavDropdown.Item>
                        <NavDropdown.Item href="/affiliates" id="nav-item">Become Affiliate</NavDropdown.Item>
                        <NavDropdown.Item href="/inviteFriend" id="nav-item">Invite Friend</NavDropdown.Item>
                        <NavDropdown.Item href="/contact" id="nav-item">Contact Us</NavDropdown.Item>
                        </NavDropdown>

                        <NavDropdown title={<strong>Join Now</strong>} id="join-nav-dropdown" style={{marginLeft: '1em'}}>
                        <NavDropdown.Item href="https://www.eziexperience.com/en/login" id="nav-item">LOG IN</NavDropdown.Item>
                        <NavDropdown.Item href="/signUp" id="nav-item">SIGN IN</NavDropdown.Item>
                        </NavDropdown>

                        {/*https://www.solodev.com/blog/web-design/using-google-translate-with-your-content.stml*/}
                        <NavDropdown title={<FontAwesomeIcon icon={faLanguage} style={{paddingBottom: '5px', fontSize: '40px'}}/>} id="icon-nav-dropdown" style={{marginLeft: '1em'}}>
                        <NavDropdown.Item id="nav-item"  onClick={() => window.open("?dummy=$random#googtrans(en)")}>USA</NavDropdown.Item>
                        <NavDropdown.Item id="nav-item" onClick={() => window.open("?dummy=$random#googtrans(en|zh-CN)")} >CHINA</NavDropdown.Item>
                        <NavDropdown.Item id="nav-item" onClick={() => window.open("?dummy=$random#googtrans(en|ja)")}>JAPAN</NavDropdown.Item>
                        <NavDropdown.Item id="nav-item" onClick={() => window.open("?dummy=$random#googtrans(en|ko)")}>KOREA</NavDropdown.Item>
                        <NavDropdown.Item id="nav-item" onClick={() => window.open("?dummy =$random#googtrans(en|vi)")}>VIETNAM</NavDropdown.Item>
                        <NavDropdown.Item id="nav-item"  onClick={() => window.open("?dummy=$random#googtrans(en|es)")}>SPAIN</NavDropdown.Item>
                        <NavDropdown.Item id="nav-item"  onClick={() => window.open("?dummy=$random#googtrans(en|th)")}>THAILAND</NavDropdown.Item>
                        <NavDropdown.Item id="nav-item"  onClick={() => window.open("?dummy=$random#googtrans(en|id)")}>INDONESIA</NavDropdown.Item>
                        
                        </NavDropdown>
                    </Nav>
             
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default Header;