import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './blog.css';

// import image
import blogImg_1 from "../../assets/img/blogImg_1.jpg";
import blogImg_2 from '../../assets/img/blogImg_2.png';
import blogImg_3 from '../../assets/img/blogImg_3.jpg';

class PostOne extends Component{
    render(){
        return(
            <div>
                <header className="post_1">
                    <div className="container">
                        <div className="row" style={{height:"80vh"}}>
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="post-heading" style={{position: 'absolute', top:'30%'}}>
                                    <h1 style={{color: 'white'}}><strong>eziexperience sessions with Meiho High School</strong></h1>
                                    <span className="meta"style={{color: 'white'}}>Posted by <a href="/">EziExiperience</a> on 18 / 08, 2020</span></div>
                            </div>
                        </div>
                    </div>
                </header>

                <article id="txtpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <p>In August, one of our affiliate schools, Meiho High School, had 12 sessions with EziExperience. Each session was conducted in a small group, which allowed all student to have more opportunities to speak and interact with E-host family.</p>
                                <p>At the beginning of the session, each student had the opportunity to introduce themselves to the E-host families; Bianca and Rubena, as well as the other students. For the first couple sessions, the students seemed a little nervous
                                    as Rubena and Bianca spoke in English only and asked students many questions to encourage engagement. However, we believe that our sessions are beneficial for students because they will be able to learn not only the language and
                                    culture but also the opportunity to talk with native speakers which will be a valuable experience for them. </p>
                                <img className="img-fluid" src={blogImg_1} alt=""/>
                                <p>In one of our sessions, Bianca took students to one of the local shopping malls to show the shops inside. There was a bank that she uses near the entrance, and she demonstrated to students how to withdraw money from the ATM. After
                                    that, Rubena took students to the supermarket to show them how to shop for groceries and answer any questions students may have about differences between shopping in Japan and Australia. This session is particularly useful for
                                    foreign students who just came or are planning to come to Australia in the future. </p>
                                <blockquote className="blockquote">
                                    <p className="mb-0"><em>our sessions are beneficial for students because they will be able to learn not only the language and culture but also the opportunity to talk with native speakers which will be a valuable experience for them.</em></p>
                                </blockquote>
                                <p>Also, the students and E-host had a fun conversation about the difference between Japanese and Australian money. The E-host family usually start the conversation with simple questions such as “Do you like this?” or “What is the colour
                                    of this?”, to make students feel more comfortable to speak up, and they will be able to expand the topic by asking “Why do you think so?”, which gives more opportunities for students to talk in the group and helps in building their
                                    confidence.</p>
                                <p>In a later session, Rubena showed how to take public transport in the Gold Coast. Some forms of transportation are not common in Japan such as ferry and tram. Therefore, students are able to gain insight and learn the process of utilizing
                                    different types of transportation throughout this session. </p>
                                <p>Visiting local beaches, museums and art galleries is one of the most entertaining sessions Rubena offers, as students will be able to see and learn more about local culture from people who are familiar with the area. In some sessions,
                                    students will see real interactions between the E-host and local residents which provide students with insight as to how they talk in their everyday life. This could be useful for students because the conversations that Japanese
                                    students learn from their English textbook might differ to how native English speakers converse in both causal and formal settings.</p>
                                <p>After students successfully completed all the sessions, they received a certificate of completing eziexperience’s Online Course. The certificate is designed similar to a boarding pass which shows the student is prepared and ready to
                                    start study abroad to Australia!</p>
                                <a href="/">
                                <img className="img-fluid" src={blogImg_2} alt=""/></a>
                                <p>Thank you for Meiho High School students for joining eziexperience, and we are so glad that they have enjoyed our sessions with a 90% satisfaction rate! </p>
                                <img className="img-fluid" src={blogImg_3} alt=""/>
                                <p>We are here to help with your preparation for studying abroad, so don’t hesitate to contact us and ask any questions you may have. We here at eziexperience are so excited to see you improving your English and getting ready for your study
                                    abroad, but more importantly, having fun with our E-host families! </p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default PostOne;