import React, {Component} from 'react';
import Slider from "react-slick";

import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/responsive.css';
import './internship.css';

// import the icon library
import { library } from '@fortawesome/fontawesome-svg-core';
import {faPlayCircle, faLightbulb, faHome, faGlobeAsia} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import image
import c1 from "../../assets/img/c1.png";
import c2 from "../../assets/img/c2.png";
import c3 from "../../assets/img/c3.png";
import i1 from "../../assets/img/i1.png";
import i2 from "../../assets/img/i2-2.png";
import i3 from "../../assets/img/i3.png";
import i4 from "../../assets/img/i4.png";
import i6 from "../../assets/img/i6.png";
import i7 from "../../assets/img/i7.png";
import asianGirl from "../../assets/img/asian-girl-.png";
import flxInt from "../../assets/img/flxint.jpg";
import jrny from "../../assets/img/jrny.svg";
import b1 from "../../assets/img/b1.png";
import b2 from "../../assets/img/b2.png";
import b3 from "../../assets/img/b3.png";
import b4 from "../../assets/img/b4.png";
import b5 from "../../assets/img/b5.png";
import heroHM from "../../assets/img/heroHM.jpg";

// Clients & Supports image
import SMEAG from "../../assets/img/1.png";
import MEIHO from "../../assets/img/2.png";
import SHANE from "../../assets/img/3.png";
import Tamagawa from "../../assets/img/4.png";
import iss from "../../assets/img/5.png";
import SWINBURNE from "../../assets/img/6.png";
import studymelbourne from "../../assets/img/7.png";
import JTB from "../../assets/img/8.png";
import Ronghuai from "../../assets/img/9.png";
import DMM from "../../assets/img/10.png";
import WISH from "../../assets/img/11.png";
import VictoriaUni from "../../assets/img/12.png";
import AussieGOV from "../../assets/img/13.jfif";
import NEAS from "../../assets/img/14.png";

library.add(
    faPlayCircle,
    faLightbulb,
    faHome,
    faGlobeAsia
    // more icon to go
);

class Intership extends Component{
    render(){
        return(
            <div>
                {/* ====== Header ======  */}
                <header id="home" className="header internship" data-stellar-background-ratio="0.5">
                    <div className="header-overlay">
                    {/* header content */}
                    <div id="hdrtxt" className="caption v-middle text-center">
                        <h3 style={{color:'white',fontWeight:'400 !important'}}>Real work. Real Experience</h3>
                        <h1 style={{color:'white',fontWeight:'800 !important'}}>Empowering Online Internship</h1>
                        <a className="vid" href="https://youtu.be/MwYrNaHw0F8">
                        <div className="vid-play">
                            <FontAwesomeIcon className="fa-5x" icon={faPlayCircle} />
                        </div>
                        </a>
                    </div>
                    </div>
                </header>
                {/* ====== End Header ======  */}

                {/*    work-area-start*/}
                <div className="work-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h3 style={{marginTop: '2em'}}>Who is it for?</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-lg-12">
                                <div className="work-all-fl">
                                    <div className="single-work-box clr-1">
                                        <div className="work-img">
                                            <img src={c1} alt="" />
                                        </div>
                                        <div className="work-text">
                                            <h3>Students</h3>
                                            <p>University students, recent graduates, or job seekers overseas</p>
                                        </div>
                                        <div className="work-btn">
                                            <a style={{color: '#000000'}} href="/signUp">Learn More <i
                                                    className="fal fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    <div className="single-work-box sec clr-2">
                                        <div className="work-img">
                                            <img src={c2} alt="" />
                                        </div>
                                        <div className="work-text">
                                            <h3>Institutions</h3>
                                            <p>Institutions, universities, or colleges that want to provide internship opportunities
                                            </p>
                                        </div>
                                        <div className="work-btn">
                                            <a style={{color: '#000000'}} href="/contact">Learn More <i
                                                    className="fal fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    <div className="single-work-box thard clr-3">
                                        <div className="work-img">
                                            <img src={c3} alt="" />
                                        </div>
                                        <div className="work-text">
                                            <h3>Companies</h3>
                                            <p>HR departments in companies</p>
                                        </div>
                                        <div className="work-btn">
                                            <a style={{color: '#000000'}}  href="contact">Learn More <i
                                                    className="fal fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*    work-area-end*/}

                {/* program-area-start */}

                <div className="program-aera" style={{backgroundColor: '#000000'}} >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h3 style={{color: 'white'}} >Our Unique Features</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="program-fx-5">
                                    <div className="single-program pgr-1">
                                        <div className="single-box-fx pgr-fx">
                                            <div className="program-icon icn-1">
                                                <img src={i1} alt="" />
                                            </div>
                                            <div className="program-content cnt-1">
                                                <h5>PROJECT BASED <br/>
                                                    LEARNING</h5>
                                                <div id="hrtxt">
                                                    <p style={{color: 'black', fontSize:'12px'}}>Modelled after Project-Based Learning (PBL)
                                                        to ensure candidates gain practical skills</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-program pgr-2">
                                        <div className="single-box-fx pgr-fx">
                                            <div className="program-icon icn-2">
                                                <img src={i2} alt="" />
                                            </div>
                                            <div className="program-content cnt-2">
                                                <h5>CUSTOMIZABLE <br/>OPTIONS</h5>
                                                <div id="hrtxt">
                                                    <p style={{color: 'black', fontSize:'12px'}}>various programs I.T., Startup, Accounting, Marketing,
                                                        Business, Sports etc</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-program pgr-3">
                                        <div className="single-box-fx pgr-fx">
                                            <div className="program-icon icn-3">
                                                <img src={i3} alt="" />
                                            </div>
                                            <div className="program-content cnt-3">
                                                <h5>INTERNATIONAL <br/>STUDENT FRIENDLY</h5>
                                                <div id="hrtxt">
                                                    <p style={{color: 'black', fontSize:'12px'}}>Our curriculum is tailored to International
                                                        student/jobseeker</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-program pgr-4">
                                        <div className="single-box-fx pgr-fx">
                                            <div className="program-icon icn-4">
                                                <img src={i4} alt="" />
                                            </div>
                                            <div className="program-content cnt-4">
                                                <h5>BILINGUAL <br/>STAFF</h5>
                                                <div id="hrtxt">
                                                    <p style={{color: 'black', fontSize:'12px'}}>Supervised by bilingual mentors & supervisors</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-program pgr-2">
                                        <div className="single-box-fx pgr-fx">
                                            <div className="program-icon icn-5">
                                                <img src={i6} alt="" />
                                            </div>
                                            <div className="program-content cnt-5">
                                                <h5>INTERCULTURAL<br/>PROGRAM</h5>
                                                <div id="hrtxt">
                                                    <p style={{color: 'black', fontSize:'12px'}}>Packaged with various intercultural programs from
                                                        different countries</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-program pgr-3">
                                        <div className="single-box-fx pgr-fx">
                                            <div className="program-icon icn-6">
                                                <img src={i7} alt="" />
                                            </div>
                                            <div className="program-content cnt-6">
                                                <h5>PROFESSIONAL<br/>DEVELOPMENT</h5>
                                                <div id="hrtxt">
                                                    <p style={{color: 'black', fontSize:'12px'}}>Our program includes a course for professional development skills</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* program-area-end */}


                {/* Choose area  Start */}
                <div className="choose-area" style={{zoom:'80%'}} >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb-50">
                                    <h2>Choose your Internship</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{justifyContent: 'space-between'}}>
                            <div className="col-lg-6" style={{padding:'2.5% !important'}}>
                                <div className="choose-blk">
                                    <div className="choose-thumb">
                                        <img src={asianGirl} alt="" />
                                    </div>
                                    <div className="choose-content">
                                        <h3>12-week Internship</h3>
                                        <h4>Australian Workplace Culture</h4>
                                        <p>Work with a host company and complete a hands-on project within 12 weeks. Enjoy
                                            complimentary employability workshops and networking sessions with fellow students and
                                            working professionals.</p>
                                        <div className="choose-btn">
                                            <a href="/contact">Explore </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6" style={{padding:'2.5% !important'}}>
                                <div className="choose-blk">
                                    <div className="choose-thumb">
                                        <img src={flxInt} alt="" />
                                    </div>
                                    <div className="choose-content">
                                        <h3 className="color-fr">Flexible Internship</h3>
                                        <h4>Summer & Winter Internship</h4>
                                        <p>Enjoy the flexibility of the internship as candidates are the one to determine the
                                            duration of the program. Receive the same practical project and complimentary workshops
                                            in a condensed timeframe.</p>
                                        <div className="choose-btn color-btn">
                                            <a href="/contact">Explore </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Choose area  End */}
      
                {/* journey-area-start */}

                <div className="journey" style={{backgroundColor:'#000000'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h3 style={{color: 'white'}}>How It Works</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="journey-wrap">
                                    <img src={jrny} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* journey-area-end */}

                {/* benifit-aera-start */}

                <div className="benifit-area" style={{backgroundColor:'white'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h3 style={{color:'#000000'}}>What's Our Benefits</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="benifit-fx">
                                    <div className="single-benifit hov-1">
                                        <div className="benifit-image">
                                            <img src={b1} alt="" />
                                        </div>
                                        <div className="benifiut-text">
                                            <p style={{padding: '0 10% 0 10%'}}>Enhance your problem-solving skills </p>
                                        </div>
                                    </div>
                                    <div className="single-benifit ben-1 hov-2">
                                        <div className="benifit-image">
                                            <img src={b2} alt="" />
                                        </div>
                                        <div className="benifiut-text">
                                            <p style={{padding: '0 10% 0 10%'}}>Communicate confidently (in English) and work as a team
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-benifit ben-2 hov-3">
                                        <div className="benifit-image">
                                            <img src={b3} alt="" />
                                        </div>
                                        <div className="benifiut-text">
                                            <p style={{padding: '0 10% 0 10%'}}>International Certificate and reference letter</p>
                                        </div>
                                    </div>
                                    <div className="single-benifit ben-3 hov-2">
                                        <div className="benifit-image">
                                            <img src={b4} alt="" />
                                        </div>
                                        <div className="benifiut-text">
                                            <p style={{padding: '0 10% 0 10%'}}>Grow their professional network</p>
                                        </div>
                                    </div>
                                    <div className="single-benifit ben-4 hov-1">
                                        <div className="benifit-image">
                                            <img src={b5} alt="" />
                                        </div>
                                        <div className="benifiut-text">
                                            <p style={{padding: '0 10% 0 10%'}}>Be industry ready and prepared to work in Australia </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* benifit-aera-end */}



                <section id="chkInt" style={{backgroundColor:'#000000'}}>
                    <div className="row">
                        <div className= "col-sm-10 col-lg-6 col-xl-6 justify-content-center">
                        <img className="img-fluid" src={heroHM} alt=""/>
                        </div>
                        <div className="col-sm-10 col-lg-6 col-xl-6 justify-content-center">
                            <div style={{alignContent: 'center'}}>
                                <div className="row justify-content-center">

                                    <h3 style={{color:'white', textAlign: 'center', paddingTop: '5rem'}}>Immersive <br/> Online
                                        Homestay</h3>
                                </div>
                                <h4 style={{textAlign: 'center', color:'white', fontSize:'20px', padding: '0 7.5%' }}>Meet. Learn. Discover</h4>
                                <p style={{textAlign: 'center', color:'white', fontSize:'22px', padding: '5%' }}>Interested to study in Australia?
                                    Get a taste of living in Australia.
                                    Learn about our immersive online homestay.
                                </p>
                                <div className="row justify-content-center" style={{paddingBottom:'3rem'}}><a className="btn btn-primary" type="button"
                                        style={{borderRadius: '20px', paddingRight: '36px', paddingLeft: '36px', color: '#313437', background: '#ff80b1', borderStyle: 'none'}}
                                        href="/homestay">Learn More</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>


                <div className="container">
                    <div className="Engage-title text-center" style={{paddingTop: '5rem'}}>
                        <h3 style={{color: '#f288b1'}}>Clients & Supports</h3>
                    </div>
                    <Slider  dots = {false} slidesToShow={6} slidesToScroll= {1} autoplay={true} autoplaySpeed={1500}>
                        <div className="slide"><a href="http://www.smeag.com"><img className="img-fluid" src={SMEAG} alt=""/></a></div>
                        <div className="slide"><a href="https://www.meiho.ed.jp"><img className="img-fluid" src={MEIHO} alt=""/></a></div>
                        <div className="slide"><a href="https://shaneschools.com/en/"><img className="img-fluid" src={SHANE} alt=""/></a></div>
                        <div className="slide"><a href="https://www.tamagawa.jp"><img className="img-fluid" src={Tamagawa} alt=""/></a></div>
                        <div className="slide"><a href="https://www.iss-ryugakulife.com"><img className="img-fluid" src={iss} alt=""/></a></div>
                        <div className="slide"><a href="https://www.swinburne.edu.au"><img className="img-fluid" src={SWINBURNE} alt=""/></a></div>
                        <div className="slide"><a href="https://www.studymelbourne.vic.gov.au"><img className="img-fluid" src={studymelbourne}alt=""/></a></div>
                    </Slider>

                    <Slider dots = {false} slidesToShow={6} slidesToScroll= {1} autoplay={true} autoplaySpeed={1500}>
                        <div className="slide"><a href="https://www.jtbcorp.jp/en/"><img className="img-fluid" src={JTB} alt=""/></a></div>
                        <div className="slide"><a href="http://studyronghuaischool.com/"><img className="img-fluid" src={Ronghuai} alt=""/></a></div>
                        <div className="slide"><a href="https://www.dmm.com"><img className="img-fluid" src={DMM} alt=""/></a></div>
                        <div className="slide"><a href="http://www.wish.co.jp"><img className="img-fluid" src={WISH} alt=""/></a></div>
                        <div className="slide"><a href="https://www.vu.edu.au"><img className="img-fluid" src={VictoriaUni} alt=""/></a></div>
                        <div className="slide"><a href="https://www.austrade.gov.au"><img className="img-fluid" src={AussieGOV} alt=""/></a></div>
                        <div className="slide"><a href="https://neas.org.au"><img className="img-fluid" src={NEAS} alt=""/></a></div>
                    </Slider>
            </div>
        </div>
        )
    }
}

export default Intership;
