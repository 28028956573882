import React, { Component } from "react";
import Slider from "react-slick";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button'

// import the icon library
import { library } from '@fortawesome/fontawesome-svg-core';
import {faPlayCircle, faLightbulb, faHome, faGlobeAsia} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// to use bootstrap css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './homestay.css';
import '../../assets/css/responsive.css';

// import image
import bico1 from "../../assets/img/bico1.svg";
import bico2 from "../../assets/img/bico2.svg";
import bico3 from "../../assets/img/bico3.svg";
import bico4 from "../../assets/img/bico4.svg";
import bico5 from "../../assets/img/bico5.svg";
import bico6 from "../../assets/img/bico6.svg";
import tm1 from "../../assets/img/tm1.png";
import tm2 from "../../assets/img/tm2.png";
import tm3 from "../../assets/img/tm3.png";
import CameronCir from "../../assets/img/CameronCir.png";
import OliverCir from "../../assets/img/OliverCir.png";
import RubenaCir from "../../assets/img/RubenaCir.png";
import ChrisCir from "../../assets/img/ChrisCir.png";
import MillyCir from "../../assets/img/MillyCir.png";
import BinacoCir from "../../assets/img/BinacoCir.png";
import ab1 from "../../assets/img/ab1.png";
import ab2 from "../../assets/img/ab2.png";
import ab3 from "../../assets/img/ab3.png";
import onint from "../../assets/img/onint.png";
// Clients & Supports image
import SMEAG from "../../assets/img/1.png";
import MEIHO from "../../assets/img/2.png";
import SHANE from "../../assets/img/3.png";
import Tamagawa from "../../assets/img/4.png";
import iss from "../../assets/img/5.png";
import SWINBURNE from "../../assets/img/6.png";
import studymelbourne from "../../assets/img/7.png";
import JTB from "../../assets/img/8.png";
import Ronghuai from "../../assets/img/9.png";
import DMM from "../../assets/img/10.png";
import WISH from "../../assets/img/11.png";
import VictoriaUni from "../../assets/img/12.png";
import AussieGOV from "../../assets/img/13.jfif";
import NEAS from "../../assets/img/14.png";

library.add(
    faPlayCircle,
    faLightbulb,
    faHome,
    faGlobeAsia
    // more icon to go
);

class Homestay extends Component {
    constructor(props) {
        super(props);
        this.handleCameronShow = this.handleCameronShow.bind(this);
        this.handleCameronClose = this.handleCameronClose.bind(this);
        this.handleOliverShow = this.handleOliverShow.bind(this);
        this.handleOliverClose = this.handleOliverClose.bind(this);
        this.handleRubenaShow = this.handleRubenaShow.bind(this);
        this.handleRubenaClose = this.handleRubenaClose.bind(this);
        this.handleChrisShow = this.handleChrisShow.bind(this);
        this.handleChrisClose = this.handleChrisClose.bind(this);
        this.handleMillyShow = this.handleMillyShow.bind(this);
        this.handleMillyClose = this.handleMillyClose.bind(this);
        this.handleBinacoShow = this.handleBinacoShow.bind(this);
        this.handleBinacoClose = this.handleBinacoClose.bind(this);

        this.state = {
            CameronShow: false,
            OliverShow: false,
            RubenaShow: false,
            ChrisShow: false,
            MillyShow: false, 
            BinacoShow: false,
        }
    }

    handleCameronShow(){
        this.setState({
            CameronShow:true
        })
    }

    handleCameronClose(){
        this.setState({
            CameronShow:false
        })
    }

    handleOliverShow(){
        this.setState({
            OliverShow:true
        })
    }

    handleOliverClose(){
        this.setState({
            OliverShow:false
        })
    }

    handleRubenaShow(){
        this.setState({
            RubenaShow:true
        })
    }

    handleRubenaClose(){
        this.setState({
            RubenaShow:false
        })
    }
    handleChrisShow(){
        this.setState({
            ChrisShow:true
        })
    }

    handleChrisClose(){
        this.setState({
            ChrisShow:false
        })
    }

    handleMillyShow(){
        this.setState({
            MillyShow:true
        })
    }

    handleMillyClose(){
        this.setState({
            MillyShow:false
        })
    }

    handleBinacoShow(){
        this.setState({
            BinacoShow:true
        })
    }

    handleBinacoClose(){
        this.setState({
            BinacoShow:false
        })
    }


    render(){
        return(
            <div>
                {/* ====== Header ======  */}
                <header id="home" className="header homestay" data-stellar-background-ratio="0.5">
                    <div className="header-overlay">
                    {/* header content */}
                        <div id="hdrtxt" className="caption v-middle text-center">
                            <h3 style={{color:'white',fontWeight:'400 !important'}}>Meet. Learn. Discover.</h3>
                            <h1 style={{color:'white',fontWeight:'800 !important'}}>Empowering Online Homestay</h1>
                            <a className="vid" href="https://youtu.be/AAJASc9shdg">
                            <div className="vid-play">
                                <FontAwesomeIcon className="fa-5x" icon={faPlayCircle} />
                            </div>
                            </a>
                        </div>
                    </div>
                </header>
                {/* ====== End Header ======  */}



                {/*feature-area-start*/}
                <div className="feature-area" style={{background:'white'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h3>Our features include </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="feature-fl">
                                    <div className="single-feature fs">
                                        <div className="feature-single">
                                            <div className="feature-single-icon"><FontAwesomeIcon icon={faLightbulb} /></div>
                                        </div>
                                        <div className="feature-text">
                                            <h3>ACTIVE LEARNING</h3>
                                            <p style={{padding:' 0 10% 0 10%'}}>We combine activities and experiences with language learning which makes learning
                                                more effective and interactive</p>
                                        </div>
                                    </div>
                                    <div className="single-feature cs">
                                        <div className="feature-single">
                                            <div className="feature-single-icon"><FontAwesomeIcon icon={faHome} /></div>
                                        </div>
                                        <div className="feature-text">
                                            <h3>CUSTOMISED PROGRAMS</h3>
                                            <p style={{padding:' 0 10% 0 10%'}}>Customised private and group sessions are provided for educational
                                                institutions and schools to make sure students can learn at their own pace</p>
                                        </div>
                                    </div>
                                    <div className="single-feature ts">
                                        <div className="feature-single">
                                            <div className="feature-single-icon"><FontAwesomeIcon icon={faGlobeAsia} /></div>
                                            <a href="/"> <i className="fas fa-globe-asia"></i></a>

                                        </div>
                                        <div className="feature-text">
                                            <h3>CAPTIONS & SUBTITLES</h3>
                                            <p style={{padding:' 0 10% 0 10%'}}>Our sessions include captions and subtitles to ensure
                                                contents delivered by our E-hosts reach clear to you. Plus, it’s great for improving
                                                your spelling</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*feature-area-end*/}




                {/*    what-area-start*/}
                <div className="what-area" style={{background:'#000000'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h3 style={{color:'white'}}>What are the benefits?</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="what-fl">
                                    <div className="single-what a1">
                                        <div className="what-icon">
                                            <a href="/signUp"><img style={{padding: '15px'}} src={bico1} alt=""/>
                                            </a>
                                        </div>
                                        <div className="what-text">
                                            <h3 style={{color:'#FFC600'}}>Convenience </h3>
                                            <p style={{padding: '0 10% 0 10%', color: 'white'}}>Practice English, tour famous attractions and get a
                                                glimpse of the Australian lifestyle, all while in your own home, snuggled up,
                                                sipping a cup of relaxing green tea. </p>
                                        </div>
                                    </div>
                                    <div className="single-what a2">
                                        <div className="what-icon">
                                            <a href="/signUp"><img style={{padding: '15px'}} src={bico2} alt=""/>
                                            </a>
                                        </div>
                                        <div className="what-text">
                                            <h3 style={{color:'#37C8CF'}}>Budget-friendly </h3>
                                            <p style={{padding: '0 10% 0 10%', color: 'white'}}>We facilitate learning virtually, so you can keep your travel expenses to a minimum.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-what a3">
                                        <div className="what-icon">
                                            <a href="/signUp"><img style={{padding: '15px'}} src={bico3} alt=""/>
                                            </a>
                                        </div>
                                        <div className="what-text">
                                            <h3 style={{color:'#FF80B1'}}>Quality learning</h3>
                                            <p style={{padding: '0 10% 0 10%', color: 'white'}}>With effective teaching methods and qualified E-hosts, we can guarantee
                                                you’ll have a quality learning experience. </p>
                                        </div>
                                    </div>
                                    <div className="single-what a4">
                                        <div className="what-icon">
                                            <a href="/signUp"><img style={{padding: '15px'}} src={bico4} alt=""/>
                                            </a>
                                        </div>
                                        <div className="what-text">
                                            <h3 style={{color:'#FFC600'}}>Caters to every student</h3>
                                            <p style={{padding: '0 10% 0 10%', color: 'white'}}>Custom learning contents are available to rightfully cater to any student at any
                                                proficiency level. </p>
                                        </div>
                                    </div>
                                    <div className="single-what a5">
                                        <div className="what-icon">
                                            <a href="/signUp"><img style={{padding: '15px'}} src={bico5} alt=""/>
                                            </a>
                                        </div>
                                        <div className="what-text">
                                            <h3 style={{color:'#37C8CF'}}>Recognition of completion </h3>
                                            <p style={{padding: '0 10% 0 10%', color: 'white'}}>We celebr/ate your success in completing the program by presenting you with an Ezi
                                                Boarding Pass.
                                                Make sure to display it, and hopefully, you’ll think of us whenever you look at it!
                                            </p>
                                        </div>
                                    </div>
                                    <div className="single-what a6">
                                        <div className="what-icon">
                                            <a href="/signUp"><img style={{padding: '15px'}} src={bico6} alt=""/>
                                            </a>
                                        </div>
                                        <div className="what-text">
                                            <h3 style={{color:'#FF80B1'}}>Sharpen conversational skills</h3>
                                            <p style={{padding: '0 10% 0 10%', color: 'white'}}>Conversational skills are just as important as spelling and grammar and you’ll have
                                                plenty of opportunities to converse in English with native speakers. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*    what-area-end*/}

                {/*    work-area-start*/}
                <div className="work-area" style={{paddingTop:'6rem'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h3>Our teaching methods</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="work-all-fl">
                                    <div className="single-work-box" style={{height:'560px'}}>
                                        <div className="work-img">
                                            <img className="img-fluid" style={{maxWidth:'200px', height:'auto'}} src={tm1} alt="" />
                                        </div>
                                        <div className="work-text">
                                            <h3>IMMERSION</h3>
                                            <p>You’ll directly experience the culture and conversing with native speakers that
                                                allows fully immersive learning</p>
                                        </div>
                                        <div className="work-btn">
                                            <a href="/signUp">Learn More <i className="fal fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    <div className="single-work-box sec" style={{height:'560px'}} >
                                        <div className="work-img">
                                            <img className="img-fluid" style={{maxWidth:'200px', height:'auto'}} src={tm2} alt=""/>
                                        </div>
                                        <div className="work-text">
                                            <h3> CLIL</h3>
                                            <p><br/>Contents that introduce the Australian culture are built into language learning.</p>
                                        </div>
                                        <div className="work-btn">
                                            <a href="/signUp">Learn More <i className="fal fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                    <div className="single-work-box thard" style={{height:'560px'}} >
                                        <div className="work-img">
                                            <img className="img-fluid" style={{maxWidth:'200px', height:'auto'}} src={tm3} alt=""/>
                                        </div>
                                        <div className="work-text">
                                            <h3>MONITOR MODEL</h3>
                                            <p>E-hosts will regularly monitor progress to make sure that contents remain engaging
                                                and helpful to you</p>
                                        </div>
                                        <div className="work-btn" >
                                            <a href="/signUp">Learn More <i className="fal fa-long-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*    work-area-end*/}

                {/* Start: 2 Rows 1+3 Columns */}
                <div id="viphosts" style={{marginTop: '24px', marginBottom: '52px'}}>
                    <div className="container" style={{marginBottom: '0px', background: 'white'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="text-center" style={{color: '#65c6ce',marginTop: '40px',marginBottom: '36px'}}>Meet our VIP E-Hosts</h1>
                                <p style={{textAlign: 'center'}}>Our top priority is providing a trust and safety for you!</p>
                                <p style={{textAlign: 'center'}}>Meet our approved local E-hosts including <strong>educators, artists, entertainers, and teachers</strong> from around the world!</p>
                            </div>
                        </div>

                        <div className="row" style={{marginBottom: '32px'}}>
                            <div className="col-md-4 text-center" style={{marginBottom: '34px'}}>
                                <img className="img-fluid" src={CameronCir} alt=""/>
                                <h2 style={{color: '#f8c828'}}>Cameron</h2>
                                <p style={{marginTop: '0px', fontFamily: 'Montserrat, sans-serif', color: '#737373'}}>Perth</p>
                                <div className="d-xl-flex justify-content-xl-center align-items-xl-center">
                                    <Modal size="lg" show={this.state.CameronShow} onHide={this.handleCameronClose}>
                                        <Modal.Body>
                                            <div className="modal-body">
                                                <div className="embed-responsive embed-responsive-16by9">
                                                    <iframe 
                                                        title="iframe1"
                                                        width="560" 
                                                        height="315" 
                                                        src="https://www.youtube.com/embed/jKb7j5EZbuw" 
                                                        allowFullScreen
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    >
                                                    </iframe>
                                                </div>
                                            </div>
                                        </Modal.Body>

                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleCameronClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <button className="btn btn-primary d-xl-flex align-items-xl-center" type="button" onClick={this.handleCameronShow} style={{fontSize: '24px'}}>Intro video</button>
                                </div>
                            </div>

                            <div className="col-md-4 text-center" style={{marginBottom: '34px'}}>
                                <img className="img-fluid" src={OliverCir} alt=""/>
                                <h2 style={{color: '#65c6ce'}}>Oliver</h2>
                                <p style={{marginTop: '0px', fontFamily: 'Montserrat, sans-serif', color: '#737373'}}>Hobart</p>
                                <div className="d-xl-flex justify-content-xl-center align-items-xl-center">
                                    <Modal size="lg" show={this.state.OliverShow} onHide={this.handleOliverClose}>
                                            <Modal.Body>
                                                <div className="modal-body">
                                                    <div className="embed-responsive embed-responsive-16by9">
                                                        <iframe 
                                                            title="iframe2" 
                                                            width="560" 
                                                            height="315" 
                                                            src="https://www.youtube.com/embed/-ZRTkPvZiWQ" 
                                                            frameBorder="0" 
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                                            allowFullScreen
                                                        >
                                                        </iframe>
                                                    </div>
                                                </div>
                                            </Modal.Body>

                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={this.handleOliverClose}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                   
                                    <button className="btn btn-primary d-xl-flex align-items-xl-center" type="button" onClick={this.handleOliverShow} style={{fontSize: '24px'}}>Intro video&nbsp;&nbsp;<i className="fa fa-play-circle-o" style={{fontSize: '24px'}}></i></button>
                                </div>
                            </div>

                            <div className="col-md-4 text-center" style={{marginBottom: '34px'}}>
                                <img className="img-fluid" src={RubenaCir} alt=""/>
                                <h2 style={{color: '#f288b1'}}>Rubena</h2>
                                <p style={{marginTop: '0px', fontFamily: 'Montserrat, sans-serif', color: '#737373'}}>Gold Coast</p>
                                <div className="d-xl-flex justify-content-xl-center align-items-xl-center">
                                    <Modal size="lg" show={this.state.RubenaShow} onHide={this.handleRubenaClose}>
                                        <Modal.Body>
                                            <div className="modal-body">
                                                <div className="embed-responsive embed-responsive-16by9">
                                                    <iframe 
                                                        title="iframe3"
                                                        width="560" 
                                                        height="315" 
                                                        src="https://www.youtube.com/embed/3gfqytETmr8" 
                                                        frameBorder="0" 
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                                        allowFullScreen
                                                    >
                                                    </iframe>
                                                </div>
                                            </div>
                                        </Modal.Body>

                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleRubenaClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    
                                    <button className="btn btn-primary d-xl-flex align-items-xl-center" type="button" onClick={this.handleRubenaShow} style={{fontSize: '24px'}}>Intro video&nbsp;&nbsp;<i className="fa fa-play-circle-o" style={{fontSize: '24px'}}></i></button>
                                </div>
                            </div>

                            <div className="col-md-4 text-center" style={{marginBottom: '34px'}}>
                                <img className="img-fluid" src={ChrisCir} alt=""/>
                                <h2 style={{color: '#f8c828'}}>Christopher</h2>
                                <p style={{marginTop: '0px', fontFamily: 'Montserrat, sans-serif', color: '#737373'}}>Hobart</p>
                                <div className="d-xl-flex justify-content-xl-center align-items-xl-center">
                                    <Modal size="lg" show={this.state.ChrisShow} onHide={this.handleChrisClose}>
                                        <Modal.Body>
                                            <div className="modal-body">
                                                <div className="embed-responsive embed-responsive-16by9">
                                                    <iframe 
                                                        title="iframe4"
                                                        width="560" 
                                                        height="315" 
                                                        src="https://www.youtube.com/embed/8V5i3-Fi-lw" 
                                                        frameBorder="0" 
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                                        allowFullScreen
                                                    >
                                                    </iframe>
                                                </div>
                                            </div>
                                        </Modal.Body>

                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleChrisClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <button className="btn btn-primary d-xl-flex align-items-xl-center" type="button" onClick={this.handleChrisShow} style={{fontSize: '24px'}}>Intro video&nbsp;&nbsp;<i className="fa fa-play-circle-o" style={{fontSize: '24px'}}></i></button></div>
                            </div>

                            <div className="col-md-4 text-center" style={{marginBottom: '34px'}}>
                                <img className="img-fluid" src={MillyCir} alt=""/>
                                <h2 style={{color: '#65c6ce'}}>Milly</h2>
                                <p style={{marginTop: '0px', fontFamily: 'Montserrat, sans-serif', color: '#737373'}}>Melbourne</p>
                                <div className="d-xl-flex justify-content-xl-center align-items-xl-center">
                                    <Modal size="lg" show={this.state.MillyShow} onHide={this.handleMillyClose}>
                                        <Modal.Body>
                                            <div className="modal-body">
                                                <div className="embed-responsive embed-responsive-16by9">
                                                    <iframe 
                                                        title="iframe5" 
                                                        width="560" 
                                                        height="315" 
                                                        src="https://www.youtube.com/embed/rQSccf9xHOA" 
                                                        frameBorder="0" 
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                                        allowFullScreen
                                                    ></iframe>
                                                </div>
                                            </div>
                                        </Modal.Body>

                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleMillyClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                <button className="btn btn-primary d-xl-flex align-items-xl-center" type="button" onClick={this.handleMillyShow} style={{fontSize: '24px'}}>Intro video&nbsp;&nbsp;<i className="fa fa-play-circle-o" style={{fontSize: '24px'}}></i></button></div>
                            </div>

                            <div className="col-md-4 text-center" style={{marginBottom: '34px'}}>
                                <img className="img-fluid" src={BinacoCir} alt=""/>
                                <h2 style={{color: '#f288b1'}}>Bianca</h2>
                                <p style={{marginTop: '0px', fontFamily: 'Montserrat, sans-serif', color: '#737373'}}>Gold Coast</p>
                                <div className="d-xl-flex justify-content-xl-center align-items-xl-center">
                                    <Modal size="lg" show={this.state.BinacoShow} onHide={this.handleBinacoClose}>
                                        <Modal.Body>
                                            <div className="modal-body">
                                                <div className="embed-responsive embed-responsive-16by9">
                                                    <iframe 
                                                        title="iframe6"
                                                        width="560" 
                                                        height="315" 
                                                        src="https://www.youtube.com/embed/D5vrAMSNPh0" 
                                                        frameBorder="0" 
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                                        allowFullScreen
                                                    >
                                                    </iframe>
                                                </div>
                                            </div>
                                        </Modal.Body>

                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleBinacoClose}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <button className="btn btn-primary d-xl-flex align-items-xl-center" type="button"onClick={this.handleBinacoShow} style={{fontSize: '24px'}}>Intro video&nbsp;&nbsp;<i className="fa fa-play-circle-o" style={{fontSize: '24px'}}></i></button></div>
                            </div>
                            <div className="col">
                                <hr/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End: 2 Rows 1+3 Columns */}

                {/* about-aera-start */}

                <div className="about-area pdng">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title">
                                    <h3 style={{marginTop: '1.25em', marginBottom: '1em'}}>Testimonials</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{paddingBottom:'64px'}}>
                            <div className="col-lg-12">
                                <div className="about-wrap">
                                    <div className="about-img">
                                        <img src={ab1} alt="" />
                                    </div>
                                    <div className="about-box-2">
                                        <div className="about-box-title">
                                            <h4 style={{color: '#000000'}}>Jenny</h4>
                                            <p className="t-margin" style={{color: '#000000c7'}}>From Thailand</p>
                                        </div>
                                        <div className="box-icon">
                                            <a href="/"><i><br/></i></a>
                                            <a href="/"><i><br/></i></a>
                                            <a href="/"><i></i></a>
                                        </div>
                                    </div>
                                    <div className="about-text-box">
                                        <p>The EziExperience’s online homestay impressed me in the way that I can feel
                                            the sense of home as well as the strong bond between me and the host. Honestly,
                                            we still keep in touch until now even after the program ended.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* about-aera-end */}


                {/* about-aera-start */}

                <div className="about-area">
                    <div className="container">
                        <div className="row" style={{paddingBottom:'64px'}}>
                            <div className="col-lg-12">
                                <div className="about-wrap wrap-2">

                                    <div className="about-img image-df">
                                        <img src={ab2} alt="" />
                                    </div>
                                    <div className="about-text-box text-box-3 thim-ng">
                                        <p>I got to know the EziExperience through one of my friends, at the time I was curious
                                            about
                                            a day of life in the city I was going to visit. Luckily, the online homestay was exactly
                                            what I was looking for,
                                            the program connected me to the host living in my destination and they showed me how
                                            their daily life would be like. I just loved it!</p>
                                    </div>
                                    
                                    <div className="about-box-2 box-3">
                                        <div className="about-box-title">
                                            <h4 style={{color: '#000000'}}>Connie</h4>
                                            <p className="t-margin" style={{color: '#000000'}}>From Indonesia</p>
                                        </div>
                                        <div className="box-icon">
                                            <a href="/"><i><br/></i></a>
                                            <a href="/"><i><br/></i></a>
                                            <a href="/"><i></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* about-aera-end */}

                {/* about-aera-start */}

                <div className="about-area pd-lsdt">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="about-wrap">
                                    <div className="about-img">
                                        <img src={ab3} alt="" />
                                    </div>
                                    <div className="about-box-2 clr-cng">
                                        <div className="about-box-title">
                                            <h4 style={{color: '#000000'}}>Yuzuru</h4>
                                            <p className="t-margin" style={{color: '#000000'}}>From Japan</p>
                                        </div>
                                        <div className="box-icon">
                                            <a href="/"><i><br/></i></a>
                                            <a href="/"><i><br/></i></a>
                                            <a href="/"><i></i></a>
                                        </div>
                                    </div>
                                    <div className="about-text-box pin0clr">
                                        <p>If you are looking for something a bit different for your next homestay, I would not
                                            hesitate to recommend EziExperience.
                                            This is one of the best platforms I could recommend for those wanting to immerse
                                            yourselves more in the country or city you are wish to visit.
                                            Not all hosts and places are the same as some may wish; consistency is not the requisite
                                            here, but very varying experiences.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* about-aera-start */}

                <section id="chkInt" style={{backgroundColor:'black'}}>
                    <div className="row" style={{width:'100%'}}>
                        <div className= "col-sm-10 col-lg-6 col-xl-6 justify-content-center" style={{padding: '0px'}}>
                        <img className="img-fluid" style={{display: 'block !important', width:'100vw !important'}} src={onint} alt=""/>
                        </div>
                        <div className="col-sm-10 col-lg-6 col-xl-6 justify-content-center" style={{padding: '0px', width:'100%'}}>
                            <div style={{alignContent: 'center'}}>
                                <div className="row justify-content-center">

                                    <h3 style={{color:'white', textAlign: 'center', paddingTop: '5rem'}}>Empowering <br/> Online
                                        Internship</h3>
                                </div>
                                <h4 style={{textAlign: 'center', color:'white', fontSize:'20px', padding: '0 7.5% 0 7.5%'}}>Real work. Real experience.</h4>
                                <p style={{fontSize: '22px', textAlign: 'center', color:'white', padding: '5%'}}>Want to live and create a future in Australia? Get a head
                                    start in securing your career in Australia.
                                    Learn about our empowering online internship program.
                                </p>
                                <div className="row justify-content-center" style={{paddingBottom:'3rem'}}><a className="btn btn-primary" type="button"
                                        style={{borderRadius: '20px',paddingRight: '36px', paddingLeft: '36px', color: '#313437', background: '#ff80b1', borderStyle: 'none'}}
                                        href="/internship">Learn More</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <div className="container">
                    <div className="Engage-title text-center" style={{paddingTop: '5rem'}}>
                        <h3 style={{color: '#f288b1'}}>Clients & Supports</h3>
                    </div>
                    <Slider  dots = {false} slidesToShow={6} slidesToScroll= {1} autoplay={true} autoplaySpeed={1500}>
                        <div className="slide"><a href="http://www.smeag.com"><img className="img-fluid" src={SMEAG} alt=""/></a></div>
                        <div className="slide"><a href="https://www.meiho.ed.jp"><img className="img-fluid" src={MEIHO} alt=""/></a></div>
                        <div className="slide"><a href="https://shaneschools.com/en/"><img className="img-fluid" src={SHANE} alt=""/></a></div>
                        <div className="slide"><a href="https://www.tamagawa.jp"><img className="img-fluid" src={Tamagawa} alt=""/></a></div>
                        <div className="slide"><a href="https://www.iss-ryugakulife.com"><img className="img-fluid" src={iss} alt=""/></a></div>
                        <div className="slide"><a href="https://www.swinburne.edu.au"><img className="img-fluid" src={SWINBURNE} alt=""/></a></div>
                        <div className="slide"><a href="https://www.studymelbourne.vic.gov.au"><img className="img-fluid" src={studymelbourne}alt=""/></a></div>
                    </Slider>

                    <Slider dots = {false} slidesToShow={6} slidesToScroll= {1} autoplay={true} autoplaySpeed={1500}>
                        <div className="slide"><a href="https://www.jtbcorp.jp/en/"><img className="img-fluid" src={JTB} alt=""/></a></div>
                        <div className="slide"><a href="http://studyronghuaischool.com/"><img className="img-fluid" src={Ronghuai} alt=""/></a></div>
                        <div className="slide"><a href="https://www.dmm.com"><img className="img-fluid" src={DMM} alt=""/></a></div>
                        <div className="slide"><a href="http://www.wish.co.jp"><img className="img-fluid" src={WISH} alt=""/></a></div>
                        <div className="slide"><a href="https://www.vu.edu.au"><img className="img-fluid" src={VictoriaUni} alt=""/></a></div>
                        <div className="slide"><a href="https://www.austrade.gov.au"><img className="img-fluid" src={AussieGOV} alt=""/></a></div>
                        <div className="slide"><a href="https://neas.org.au"><img className="img-fluid" src={NEAS} alt=""/></a></div>
                    </Slider>
                </div>

            </div>
        )
    }
}

export default Homestay;
