import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './contactUs.css';

import c3_1 from '../../assets/img/c3_1.png';
import c1_1 from '../../assets/img/c1_1.png';

function  Step1(props){
    var selectHomestay = "card-block text-center radio";
    var selectInternship = "card-block text-center radio";
    
    if(props.service1.includes("homestay")){
        selectHomestay = "card-block text-center radio selected";
    }else{
        selectHomestay = "card-block text-center radio";
    }

    if(props.service1.includes("internship")){
        selectInternship = "card-block text-center radio selected";
    }else{
        selectInternship = "card-block text-center radio";
    }

    function handleHome(){
        props.handleServiceChange("service1","homestay")
    }

    function handleIntern(){
        props.handleServiceChange("service1","internship")
    }

    if (props.currentStep !== 1){
        return null;
    }

    return(
        <div>
            <div className="form-card" style={{marginTop: '1rem'}}>
                <h5 className="sub-heading">Select Service(s)</h5>
                <div className="row px-1 radio-group">
                    <div className={selectHomestay} onClick={handleHome}>
                        <div className="image-icon"> 
                            <img className="icon icon1" src={c3_1} alt="" /> 
                        </div>
                        <p className="sub-desc">Online Homestay</p>
                    </div>
                    <div className={selectInternship} onClick={handleIntern}>
                        <div className="image-icon"> 
                            <img className="icon icon1 fit-image" src={c1_1} alt="" /> 
                        </div>
                        <p className="sub-desc">Online Internship</p>
                    </div>
                </div>
            </div>
        </div>
    )
    


}

export default Step1;