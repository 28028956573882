import React, {Component} from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import emailjs from 'emailjs-com';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './affiliates.css';

class Affiliates extends Component{
    constructor(props){
        super(props);
        this.state = {
            // user details
            email: "",
            lastName: "",
            firstName: "",
            address: "",
            website: "",
            company:"",
            mobile: "",
            city: "",
            state: "",
            zipCode: "",
            typeBusiness: "",
            monthlyVisits: "",
            message: "",
            privacy: false,

            /*Alerts*/
            alerFormInfo: false,
            alertPriacy: false,
            alertSubmit: false,
        }
        this.myRef = React.createRef();
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(e){

        if(this.state.email === "" || this.state.firstName === "" || this.state.lastName === "" || this.state.company === "" || this.state.address === "" || this.state.website === ""){
            console.log("here");
            this.setState({
                alerFormInfo: true
            })
        } else if (this.state.privacy === false){
            this.setState({
                alertPriacy: true
            })
        } else {

            this.setState({
                alerFormInfo: false,
                alertPriacy: false
            })

            e.preventDefault();

            var userInfor = {
                subject_name: "Affiliate Details",
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                email: this.state.email,
                address: this.state.address,
                website: this.state.website,
                company: this.state.company,
                mobile: this.state.mobile  ===''? "User doesn't provide this information" : this.state.mobile,
                city: this.state.city ===''? "User doesn't provide this information" : this.state.city,
                state: this.state.state ===''? "User doesn't provide this information" : this.state.state,
                zip_code: this.state.zipCode ===''? "User doesn't provide this information" : this.state.zipCode,
                type_of_business: this.state.typeBusiness,
                monthly_visits: this.state.monthlyVisits ===''? "User doesn't provide this information" : this.state.monthlyVisits,
                message: this.state.message === ''? "User doesn't provide this information" : this.state.message,
            }
            console.log(userInfor);

            emailjs.send('gmail', 'template_6ok1gew', userInfor, 'user_zfg6ZlcWtvwNJabsQj93Z')
            .then((result) => {
                if(result.status === "200"){
                    this.setState({
                        alertSubmit: true
                    })
                }
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        }
        
    }

    render(){
        return(
            <div>
                 <header className="masthead Affiliates">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="site-heading">
                                    <h1 style={{fontSize: '69px', color:'white'}}>
                                        <strong>Let's work together&nbsp;</strong></h1>
                                        <span className="subheading" style={{marginBottom: '24px', fontSize: '33px'}}>
                                            Spread the word about EziExperience to your community and earn extra for every referral made.
                                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-lg-8 mx-auto" style={{textAlign: 'left'}}>
                            <h1 style={{textAlign: 'center', fontSize: '64px', color: '#000000', marginTop: '32px'}}>
                                <strong><span style={{textDecoration: 'underline'}}>Ready to get started?</span></strong><br/></h1>
                            <h1 style={{fontSize: '48px', color: '#f8c828', marginTop: '24px'}}><strong>1. Sign up</strong></h1>
                            <p>Fill the form below with your most up-to-date details. <br/>Keep an eye out as our team will be in touch with you!</p>
                            <h1 style={{fontSize: '48px', color: '#65c6ce', marginTop: '32px'}}><strong>2. Share the link</strong></h1>
                            <p style={{marginTop: '24px', marginBottom: '28px'}}>
                                Get your referral link and start sharing it with your community. Don’t worry, we’ll provide you with the appropriate materials to help you get as many referrals as you can.</p>
                                
                            <button 
                                className="btn btn-primary" 
                                type="button" 
                                style={{marginBottom: '32px', background: '#65c6ce', borderRadius: '56px', borderColor: '#65c6ce'}}
                                onClick={()=> this.myRef.current.scrollIntoView()}>
                                Sign Up to share
                            </button>
                    
                            <h1 style={{fontSize: '48px', textAlign: 'left', color: '#f288b1', marginTop: '32px'}}><strong>3. Earn extra</strong><br/></h1>
                            <p><br/>Earn for every referral you make. You can earn up to $30 per sale and bonuses based on your sales volume and performance.<br/></p>
                            <ul className="list-unstyled text-left" style={{textAlign: 'left'}}>
                                <li style={{color:' #f8c828', fontSize: '24px'}}>50 sales per month to receive a $100 bonus<br/></li>
                                <li style={{color:' #65c6ce', fontSize: '24px'}}>100 sales per month to receive a $200 bonus</li>
                                <li style={{color:' #f288b1', fontSize: '24px'}}>200 sales per month to receive a $500 bonus</li>
                            </ul>
                            <hr style={{marginTop: '54px', marginBottom: '54px'}} />
                            
                            {/* Start: Application Form */}
                            <section id="signUpform" ref={this.myRef}>
                                <h1 style={{fontSize: '64px', textAlign: 'center', color:'#000000', marginTop: '32px'}}><strong><br/>Sign Up today</strong><br/></h1>
                                <p style={{textAlign: 'center'}}>Fill up the form &amp; Sign Up today to enjoy the benefits</p>

                                <Form id="contact-form" onSubmit={(e) => this.onSubmit(e)}>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridFirstName">
                                    <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
                                    <Form.Control placeholder="EX. John" onChange={ e => this.setState({firstName: e.target.value})}/>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridLastName">
                                    <Form.Label>Last Name <span className="text-danger">*</span> </Form.Label>
                                    <Form.Control placeholder="EX. Smith" onChange={ e => this.setState({lastName: e.target.value})}/>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Group controlId="formGridCompanyName">
                                    <Form.Label>Company Name <span className="text-danger">*</span></Form.Label>
                                    <Form.Control placeholder="EX. Ezi Experience" onChange={ e => this.setState({company: e.target.value})}/>
                                </Form.Group>

                                <Form.Group controlId="formGridWebsite">
                                    <Form.Label>Website <span className="text-danger">*</span></Form.Label>
                                    <Form.Control placeholder="EX. http://www.eziexperience.com" onChange={ e => this.setState({website: e.target.value})}/>
                                </Form.Group>

                                <Form.Group controlId="formGridEmail">
                                    <Form.Label>Email <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="email" placeholder="EX. user@domain.com" onChange={ e => this.setState({email: e.target.value})}/>
                                </Form.Group>

                                <Form.Group controlId="formGridAddress">
                                    <Form.Label>Address <span className="text-danger">*</span></Form.Label>
                                    <Form.Control placeholder="EX. Room No-361, 33/1, 3rd Floor" onChange={ e => this.setState({address: e.target.value})}/>
                                </Form.Group>

                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control placeholder="EX. Melbourne" onChange={ e => this.setState({city: e.target.value})}/>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control placeholder="EX. VIC" onChange={ e => this.setState({state: e.target.value})}/>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridZip">
                                    <Form.Label>Zip</Form.Label>
                                    <Form.Control placeholder="EX. 3000" onChange={ e => this.setState({zipCode: e.target.value})}/>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridBusiness">
                                    <Form.Label>Type of Business</Form.Label>
                                    <Form.Control as="select" defaultValue="Choose..." onChange={ e => this.setState({typeBusiness: e.target.value})}>
                                        <option value="Travel Website">Travel Website</option>
                                        <option value="Blog">Blog</option>
                                        <option value="Education Partner">Education Partner</option>
                                        <option value="Meta Search Site">Meta Search Site</option>
                                        <option value="OTA">OTA</option>
                                        <option value="Online Tutorial Site">Online Tutorial Site</option>
                                        <option value="Other">Other</option>
                                    </Form.Control>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridVisits">
                                    <Form.Label>Monthly visits</Form.Label>
                                    <Form.Control placeholder="EX. 200" onChange={ e => this.setState({monthlyVisits: e.target.value})}/>
                                    </Form.Group>
                                </Form.Row>

                                <Form.Group controlId="exampleForm.ControlMessage">
                                    <Form.Label>Send a message</Form.Label>
                                    <Form.Control as="textarea" rows={3} onChange={ e => this.setState({message: e.target.value})}/>
                                </Form.Group>

                                <Form.Group controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" 
                                        onChange={ e => this.setState({privacy: e.target.checked})}  
                                        label={
                                            <label>I read &amp; understand {' '}
                                            <a href="/privacy"><span style={{color: 'blue'}}>
                                            <strong>Privacy Policy {' '} </strong> </span>
                                            </a>&nbsp;of the Ezitech corporation 
                                            </label>
                                        }
                                    />
                                </Form.Group>

                                {this.state.alerFormInfo ? (
                                    <Alert variant="danger">Please fill in all <span className="text-danger">*</span> information</Alert>
                                ):(
                                    null
                                )}

                                {this.state.alertPriacy ? (
                                    <Alert variant="danger">Please read & understand Privacy Policy  of the Ezitech corporation</Alert>
                                ):(
                                    null
                                )}

                                <button className="btn btn-primary btn-light m-0 rounded-pill px-4" style={{minWidth: '150px', background:'#65c6ce'}} type="submit" value="Send">
                                    Submit
                                </button>
                                {this.state.alertSubmit ? (
                                    <Alert variant="success">SUCCESS! Your information is submitted, will be in touch soon</Alert>
                                ):(
                                    null
                                )}
                                </Form>

                            </section>
                            {/* End: Application Form */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Affiliates;