import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './blog.css';

class PostTwo extends Component{
    render(){
        return(
            <div>

                <header className="post_2">
                    <div className="container">
                        <div className="row" style= {{height:'80vh'}}>
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="post-heading" style={{position: 'absolute', top:'30%'}}>
                                    <h1 style={{color:'white'}}><strong>How to support learning during a pandemic</strong><br/></h1><span className="meta">Posted by&nbsp;<a href="/">EziExperience</a>&nbsp;on 20/07/2020</span></div>
                            </div>
                        </div>
                    </div>
                </header>

                <article id="txtpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <p>The Coronavirus pandemic has affected the education of children around the world. With over 190 countries closing schools and moving curriculums online UNESCO estimates over 1.5 billion students have been affected. This has drastically
                                    changed the normal school routine most children and teenagers are used to and moved learning online. So, how can parents support learning during these difficult times?</p>
                                <p>Follow our simple 5 steps below to keep your child engaged while learning at home.</p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#f8c828'}}><strong>1.Establish a routine</strong><br/></h2>
                                <p>Make a timetable for your child. Just like in school keep time with how much your child learns in a certain area, allowing for breaks in between for flexibility. Make a study corner in the bedroom or an area of the house where your
                                    child can concentrate and let them know that area is for learning. This will help your child concentrate and avoid distractions when learning. </p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#65c6ce'}} ><strong>2.Create a space for reading time together</strong><br/></h2>
                                <p>Reading is known to help develop early literacy skills, spark your child’s imagination and has been proven to result in children doing academically well into their future. But creating a space for reading time together is not only
                                    a way to get your kids into reading as a habit but also a fun family activity. If you have younger children aged 1-8-year-olds reading aloud by creating Story time is a fun activity the kids will love. Motivating your children
                                    to read will lead to a life-long good habits of developing creativity, social and intelligence skills. </p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#f288b1'}}><strong>3.Let your child keep in touch with his/her friends</strong><br/></h2>
                                <p>Learning from home takes away what most children look forward to in school – seeing their friends, and this applies to kids in all age groups. Kids find it easy to focus, pay attention and thereby develop their social skills by playing
                                    and learning together. This may be hard if you are learning from home or in a place that has restrictions. Introducing other ways for your child to keep in contact like writing letters, zoom, skype etc. can bring joy and help them
                                    keep in touch. </p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#65c6ce'}}><strong>4.Engage your child in extracurricular activities</strong><br/></h2>
                                <p>Just because your children are at home doesn’t mean they have more free time. Sooner or later your child is going to get bored. When in school there were many extracurricular activities such as clubs and sports. Make sure your child
                                    is still engaged. Introduce them to a hobby such as painting, learning an instrument or learning a new language and culture. The internet gives your access to easy learning from home. Introducing your child to English lessons while
                                    teaching them about Australian culture teaches your child about the wider world and the different types of people and lifestyles. English language learning is also a highly sought-after skill in the academic and professional worlds.</p>
                                <h2
                                    className="section-heading" style={{fontSize: '32px', color: '#f8c828'}}><strong>5.Listen to your child’s interests and needs</strong><br/></h2>
                                    <p>Each child is different and as such each will experience the changes of the pandemic differently. Encourage your child to ask questions and express their feelings. Inform them about rising issues and current affairs. Have open
                                        conversations with your child and any worries or concerns they may have while learning at home. Children are intuitive by nature and a lot smarter than you think. If your child is older warn against fake news and trusting sources
                                        online. By taking your child’s interest and needs to heart you create a positive environment in your home, which greatly helps in developing your child’s learning and progress. </p>
                                    <p style={{marginTop: '36px'}}>All these simple steps will help in fostering a positive environment for your child at home during these trying times.&nbsp;</p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default PostTwo;