import React, { Component } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './host.css';

class LearnerTerm extends Component{
    render(){
        return(
            <div>
                  <header className="host-terms">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="site-heading">
                                    <h1 style={{fontSize: '64px', textAlign: 'left', color: 'white'}}><strong>Ezi Experience&nbsp;</strong></h1>
                                    <h1 style={{fontSize: '64px', textAlign: 'left', color: 'white'}}>Terms &amp; Conditions&nbsp;<br/><strong>for learners</strong><br/></h1>
                                    <h2 className="subheading" style={{textAlign: 'left', color: 'white'}}>code of conduct &amp; terms</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <article id="txtpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <h2 className="section-heading"><strong>Code of conduct for the learners</strong></h2>
                                <p><strong>The following are the codes that need to be conducted by learners/students</strong></p>
                                <ul>
                                    <li>Respect the professionalism of your E-Host</li>
                                    <li>Not allow to behave in an offensive, aggressive, threatening, harassing, racist or xenophobic nature, or with sexual connotations, inciting violence, discrimination or hatred towards your host and surrounding people</li>
                                    <li>All critical information with regards to EziExperience of you or its learners must be communicated to the EziExperience coordinator. Such information includes but not limited to the conflict between a learner and E-host, a change
                                        in a request to change e-hosts, and notification to cancel the sessions.</li>
                                    <li>You or its learners can't contact host family directly and request personal contact information. (Phone, address, e-mail, Facebook, Twitter, Skype, etc)&nbsp;</li>
                                    <li>You or its learners can't use the message system to exchange address information before the respective booking is confirmed</li>
                                    <li>EziExperience Coordinator can be reached at eziexperience@ezitech.co</li>
                                </ul>
                                <h2 className="section-heading"><strong>CANCELLATION POLICY</strong></h2>
                                <p><strong>BEFORE COMMENCEMENT</strong><br/></p>
                                <ul>
                                    <li>If you or its learners cancel the session and provide more than 7 days written notice prior to the first session, the fees will be refunded in full. The session fee is not refundable.&nbsp;</li>
                                    <li>If you or its learners cancel the session and provide less than 7 days written notice prior to the first arrival, the fees and session fee won't be refunded in. </li>
                                </ul>
                                <p><strong>AFTER COMMENCEMENT</strong><br/></p>
                                <ul>
                                    <li>If learners cancel the session and provide more than 72 hours before the starting time of a session, the learner will receive the session credits for the full value of your fees – recredit value will valid for 1 month.&nbsp;</li>
                                    <li>If learners cancel the session and provide less than 72 hours before the starting time of a session, the learner won't receive a credit for the full value of the remaining session(s). </li>
                                    <li>If e-hosts cancel the session, the learner will receive the session credits for the full value of your fees or EziExperiencefind alternative e-host immediately. </li>
                                    <li>If learner is late for longer than 5 mins, it means no show and the host family can cancel the session. </li>
                                </ul>
                                <h2 className="section-heading"><strong>REFUND POLICY</strong><br/></h2>
                                <ul>
                                    <li>For refunds on periods greater than the first 12 sessions, a pro-rata of credit for the value of the remaining sessions will be calculated, based on the number of sessions spent according to the current rates.</li>
                                    <li>In order to receive a refund, the learner must give written notice to EziExperience coordinator eziexperience@ezitech.co </li>
                                    <li>All refunds are by Paypal or bank transfer and take up to 3 weeks from the date of received notice. </li>
                                </ul>
                                <h2 className="section-heading"><strong>Video recording, and Intellectual Property</strong><br/></h2>
                                <ul>
                                    <li>Learners understand that you will be using EziExperience’s Intellectual Property, including all content provided on the EziExperience service, is protected by copyright, trade secret, or other intellectual property laws and treaties.
                                        </li>
                                    <li>Learners understand and consent to having your video recorded during each session with the learner to practice your session. The recordings of the session may be held, saved, and stored by EziExperience solely to release it for
                                        educational/safety purposes, and for no other reasons. </li>
                                </ul>
                                <p><strong>EziExperiencere serves the right to change any of these conditions at any time deemed necessary to reflect current market and economic conditions.&nbsp;&nbsp;</strong><br/></p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default LearnerTerm;