import React, { Component } from "react";
import emailjs from 'emailjs-com';

// to use bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './footer.css';

import Logo from '../../assets/img/EziExpLogo.svg';

import { library } from '@fortawesome/fontawesome-svg-core';
import {faFacebookF, faInstagram, faLinkedinIn, faYoutube} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(
    faFacebookF,
    faInstagram,
    faLinkedinIn,
    faYoutube
    // more icon to go
);

class Footer extends Component {
    constructor(props){
        super(props);
        this.state={
            firstName: "",
            lastName: "",
            email: "",
        }
        this.onSubmit = this.onSubmit.bind(this);
    }
    
    onSubmit(e){
        e.preventDefault();

        var userInfor = {
            subject_name: "Subscriber Details",
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email
        }

        emailjs.send('gmail', 'template_6ok1gew', userInfor, 'user_zfg6ZlcWtvwNJabsQj93Z')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        
    }

    render(){
        return(
            <footer>
            <div className="footer-area">
                <div className="row">
                    <div className="col-lg-4" style={{paddingLeft: '10%'}}>
                        <div className="footer-widget-wrapper">
                            <div className="row" >
                                <div className="col-md-5" >
                                    <div className="footer-blk">
                                        <div className="footer-widget">
                                            <div className="footer-logo justify-content-center">
                                                <h1 style={{padding: '10%'}}>
                                                <img alt="" src={Logo} style={{width: "100%", height: "100%"}}/>
                                                </h1>
                                            </div>
                                            <div className="footer-body-text">
                                                <h4 style={{fontSize: '18px', color:'white'}}>The ultimate virtual experience of living abroad</h4>
                                                <p style={{color: 'white', fontSize: '15px'}}>© 2021 EziTech. 
                                                    All rights reserved.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="footer-blk">
                                            <div className="footer-widget">
                                                <div className="footer-social">
                                                    <ul>
                                                        <li><a href="https://www.facebook.com/EziExperience-100930465064155"><FontAwesomeIcon icon={faFacebookF}/></a></li>
                                                        <li><a href="https://www.instagram.com/eziexperience/"><FontAwesomeIcon icon={faInstagram}/></a></li>
                                                        <li><a href="https://www.linkedin.com/company/ezihomestay"><FontAwesomeIcon icon={faLinkedinIn}/></a></li>
                                                        <li><a href="https://www.youtube.com/channel/UCyvLuk0AUvh5gUZozRHjyIg"><FontAwesomeIcon icon={faYoutube}/></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4" >
                                        <div className="footer-blk">
                                            <div className="footer-widget">
                                                <h4>Meet.</h4>
                                            </div>
                                            <div className="footer-list">
                                                <li><a href="/aboutUs" >Company</a></li>
                                                <li><a href="/aboutUs#team" >Team</a></li>
                                                <li><a href="/contact" >Contact Us</a></li>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="footer-widget-wrapper">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="footer-blk">
                                            <div className="footer-widget">
                                                <h4>Learn.</h4>
                                            </div>
                                            <div className="footer-list">
                                                
                                                <li><a href="/homestay">
                                                            <h4 style={{color: "#37c8cf"}}>Online Homestay</h4>
                                                    </a>
                                                </li>
                                                <li><a href="/howitworks" >How it works for Hosts</a></li>
                                                <li><a href="/hostterms" >Terms for Hosts</a></li>
                                                <li><a href="/hostfaq" >FAQ for Hosts</a></li>
                                                <li><a href="/Terms" >Terms for Learner</a></li>
                                                    
                                                <li> <a href="/Internship">
                                                        <h4 style={{color: "#ffc600"}}>Online Internship</h4>
                                                    </a>
                                                </li>

                                                <li><a href="/faqInternship" >FAQ Internships</a></li>

                                                <li style = {{marginBottom: "50px"}}><a href="/privacy">Privacy Policy</a></li>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="footer-blk">
                                            <div className="footer-widget">
                                                <h4>Discover.</h4>
                                            </div>
                                            <div className="footer-list">
                                                
                                                <li><a href="/homestay" >Community</a></li>
                                                <li><a href="/blog">Blog</a></li>
                                                <li><a href="/affiliates" >Become an Affiliate</a></li>
                                                <li><a href="/inviteFriend" >Invite Friend</a></li>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="footer-blk">
                                            <div className="footer-widget">
                                                <h4>Subscribe</h4>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="single-input">
                                                    <input type="text" placeholder="First Name" onChange={e => this.setState({firstName: e.target.value})}/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="single-input">
                                                    <input type="text" placeholder="Last Name" onChange={e => this.setState({lastName: e.target.value})}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <form>

                                            <div className="col-lg-12">
                                                <div className="single-input">
                                                    <input type="Email" placeholder="Email address" onChange={e => this.setState({email: e.target.value})}/>
                                                </div>
                                                <div className="form-group"><button className="btn btn-primary" type="submit" onClick={e => this.onSubmit(e)}>Subscribe </button></div>
                                            </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            </footer>
        )
    }
}

export default Footer;