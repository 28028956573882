import React, { Component } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './host.css';

class Privacy extends Component{
    render(){
        return(
            <div>
                <header className="privacy-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="site-heading">
                                    <h1 style={{color:'white'}}><strong>Privacy policy&nbsp;</strong></h1><span className="subheading">EZITECH Corporation Pyt Ltd | ABN 89 644 755 322&nbsp;</span><span className="subheading" style={{textAlign: 'right'}}>( Last updated: Oct-2020 )</span></div>
                            </div>
                        </div>
                    </div>
                </header>

                <article id="txtpg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-lg-8 mx-auto">
                            <p>This is the privacy notice to inform you of our policy about all information that we record about you. It covers both information that could and could not identify you and information.</p>
                            <p>We are extremely concerned to protect your privacy and confidentiality. Our policy is both specific and strict. It complies with Australian law. </p>
                            <p>EziExperience(from here on referred to as "EziExperience", "we", "us" or "our") provides this Privacy policy to inform you of our policies and procedures regarding how we collect and process the personal data that we receive from users
                                of www.eziexperience.co (the "Site"), our services regardless of how you use or access them. Personal data is any information that directly or indirectly identifies you. ‘Directly’ could for example be through a name. ‘Indirectly’ refers
                                to the situation where you can still be identified without your name through or by combining other information. </p>
                            <p>When you register with us through the Site and when you choose to serve as a host or when you wish to contact another learner via our Site, we will ask you for personal data that can identify you and be used to contact you ("Identity Information").
                                Identity Information includes, but is not limited to; your name, phone number, email address, home address, but does not include your credit card number or banking information. </p>
                            <p>In regards to this policy, the terms "using" and "processing" information in this Privacy policy include but are not limited to; the use of cookies; and the collection, storing, transfer, evaluation, deletion, disclosure, management, handling,
                                modifying and use of personal data. </p>
                            <p>This Privacy policy applies only to personal data that you provide to us via the Site and the Application. We reserve the right to update this Privacy policy from time to time to reflect any changes to our services. We will do this by
                                amending the Privacy policy on the Site. The changes will take effect automatically, as soon as they are posted on the Site. </p>
                            <h1><strong>Processing of personal data</strong></h1>
                            <p>We may process your personal data for the following purposes: </p>
                            <p><strong>Providing our services  </strong></p>
                            <p>EziExperience might process your personal data to provide its services. For example, personal data is processed in order to set up your EziExperience account, allow you to book an event through, and submit reviews to the Site, App or third-party
                                widgets(TBD). </p>
                            <p>For this purpose, EziExperience processes the following personal data: name, phone number, email address, home address, IP address, EziExperience Account profile data.(TBD) </p>
                            <p><strong>Customer service</strong><br/></p>
                            <p>EziExperience processes personal data to handle questions and requests. This processing is necessary to provide our services. </p>
                            <p>For this purpose, EziExperience processes your name and email address and booking information. EziExperience is allowed to process personal data for this purpose because it is necessary for the legitimate interest of EziExperience(or a
                                third party), essentially to enable EziExperience to adequately respond to questions and requests </p>
                            <p><strong>(Direct) marketing</strong><br/></p>
                            <p>EziExperience processes personal data for (direct) marketing purposes. This means that EziExperience can contact customers to draw attention to our products. For this purpose, EziExperience processes the following personal data: IP address,
                                email address, postal address, phone number, online identifiers, booking information, location, and account information. </p>
                            <p>EziExperienceis allowed to process personal data for this purpose when individuals have given consent or when EziExperiencehas a legitimate interest to process personal data. Depending on the ground of processing (legitimate interest versus
                                consent), you have the right to withdraw your consent at any time or have the right to object to the processing of your personal data for direct </p>
                            <p>marketing purposes at any time. The withdrawal or exercise of your right of the objection shall not affect the lawfulness of the processing prior to the withdrawal or exercise of the right of objection. </p>
                            <h1><strong>Categories of recipients</strong><br/></h1>
                            <p>We may process your personal data for the following purposes: </p>
                            <p>It may be necessary for EziExperienceto disclose your personal data whether by law, possibly in the context of litigation, legal process, and / or by request from public or governmental authorities within or outside of your country of
                                residence. We may also disclose your personal information if we determine that disclosure is necessary or appropriate for purposes of law enforcement, national security or to prevent or stop any activity we may consider to be or to
                                pose a risk of being, illegal, unethical, or legally actionable. </p>
                            <p>We will disclose any information about you to our insurance services providers if we consider it necessary in order to protect the property and rights of EziExperience or a third party, and / or to protect the safety of the public or any
                                person. </p>
                            <p>In order to provide and improve our services, we will share information with third parties when needed. </p>
                            <p style={{marginBottom: '20px', marginTop: '20px'}}>If you make a booking, EziExperience will share the following information about you with the host: (i) your first and last name, and (ii) a link to your EziExperience Account. This information will enable the host to decide whether to
                                confirm or reject the booking. When a booking is confirmed, we will also share your information with the host. Under no circumstance is your billing information ever shared with a host. </p>
                            <p style={{marginBottom: '20px'}}>We may employ third party companies and individuals to help improve or facilitate our service, to provide the service on our behalf, to perform platform-related services, including but not limited to: maintenance services, fraud detection
                                services, database management, web analytics, monitoring and evaluation services. </p>
                            <p style={{marginTop: '0px'}}>These third parties may have access to your personal information. If they do, your information is still protected by the terms of our Privacy Policy. </p>
                            <p style={{marginTop: '47px'}}>You can always withdraw you consent to the receipt of such communications by sending an email to eziexperience@ezitech.co. </p>
                            <h1><strong>Your rights and how to exercise them&nbsp;</strong></h1>
                            <p style={{marginBottom: '0px', marginTop: '29px'}}>We respect the exercise of the rights you have in relation to the personal data we process or use. </p>
                            <p style={{marginTop: '20px'}}>You can request access to or a copy of your personal data collected and processed by us. You may also request the rectification and removal of personal data or the restriction of the processing of your personal data if there is a reason
                                for doing so. You also have the right to data portability. You also have the right to </p>
                            <p style={{marginTop: '20px'}}>object to processing on grounds relating to your particular situation or against the processing of your personal data for direct marketing purposes. To exercise these rights, please contact our team via EziExperience.co. To prevent misuse,
                                we may ask you to identify yourself. The law determines whether you can exercise a certain right in a given situation. You should, therefore, bear in mind that we are not always able to execute your right; at full. </p>
                            <h1><strong>Security</strong><br/></h1>
                            <p style={{marginBottom: '0px', marginTop: '29px'}}>We take security very seriously. EziExperience is very concerned with safeguarding your information. We take the appropriate technical and organizational measures to safeguard your personal data. </p>
                            <p style={{marginTop: '20px'}}>In the event of any breach of security, EziExperience will make any legally required disclosures concerning the breach and the confidentiality, or integrity of your unencrypted electronically stored "personal data" to you via email or
                                via a posting on the site and application without unreasonable delay in as far as it is consistent with any legitimate needs of law enforcement and any measures required to determine the scope of the breach and to safeguard the integrity
                                of data. </p>
                            <h1><strong>Retention periods</strong><br/></h1>
                            <p style={{marginBottom: '0px', marginTop: '16px'}}>We do not keep your personal data longer than necessary for the purpose of the processing. </p>
                            <h1 style={{marginTop: '40px'}}><strong>Questions or complaints</strong><br/></h1>
                            <p style={{marginBottom: '0px', marginTop: '16px'}}>When we receive a complaint, we record all the information you have given to us. We use that information to resolve your complaint. If your complaint reasonably requires us to contact some other person, we may decide to give to that other
                                person some of the information contained in your complaint. We do this as infrequently as possible, but it is a matter for our sole discretion as to whether we do give information, and if we do, what that information is. </p>
                            <p style={{marginBottom: '0px', marginTop: '30px'}}>We may also compile statistics showing information obtained from this source to assess the level of service we provide, but not in a way that could identify you or any other person. </p>
                            <p style={{marginBottom: '0px', marginTop: '30px'}}>If you have any questions or complaints about the processing of your personal data, please send an e-mail to eziexperience@ezitech.co. </p>
                            <p style={{marginBottom: '0px', marginTop: '30px'}}><strong>EziExperiencere serves the right to change any of these conditions at any time deemed necessary to reflect the current market and economic conditions.    </strong></p>
                            <p style={{marginBottom: '0px', marginTop: '30px'}}><strong><em>EZITECH Corporation Pyt Ltd</em></strong><br/></p>
                        </div>
                    </div>
                </div>
                </article>
                <hr/>
            </div>
        )
    }
}

export default Privacy;