import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './contactUs.css';

const Step4 = props =>{

    if (props.currentStep !== 4){
        return null;
    }

    return(
        <div style={{marginTop: '1rem'}}>
            <h5 className="sub-heading mb-4">Company Details</h5>
            
            <Form>
                <Form.Group as={Col} controlId="company">
                <Form.Label>Company Name</Form.Label>
                <Form.Control defaultValue={props.company} onChange={props.handleChange}/>
                </Form.Group>

                <Form.Group as={Col} controlId="zipCode">
                <Form.Label>Zip code</Form.Label>
                <Form.Control defaultValue={props.zipCode} onChange={props.handleChange}/>
                </Form.Group>
               

                <Form.Group as={Col} controlId="state">
                    <Form.Label>State</Form.Label>
                    <Form.Control defaultValue={props.state} onChange={props.handleChange}/>
                </Form.Group>

                <Form.Group as={Col} controlId="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control defaultValue={props.city} onChange={props.handleChange}/>
                </Form.Group>

                <Form.Group as={Col} controlId="message">
                    <Form.Label>Send a message</Form.Label>
                    <Form.Control as="textarea" rows={3} defaultValue={props.message} onChange={ props.handleChange}/>
                </Form.Group>

            </Form>
        </div>
    )
    
}

export default Step4;