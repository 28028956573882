import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './contactUs.css';



const Step5 = props =>{

    if (props.currentStep !== 5){
        return null;
    }

    return(
        <div className="form-card" style={{marginTop: '1.5rem'}}>
            <h5 className="sub-heading mb-4">Success!</h5>
            <p className="message">
                Thank You for choosing our website.
                <br/>
                Quotation will be sent to your Email ID and Contact Number.
            </p>
            <div className="check"> 
                <img className="fit-image check-img" src="https://i.imgur.com/QH6Zd6Y.gif" alt="" /> 
            </div>
        </div>
    
    )
}

export default Step5;