import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './blog.css';


class PostThree extends Component{
    render(){
        return(
            <div>
                <header className="post_3">
                    <div className="container">
                        <div className="row" style={{height:"80vh"}}>
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="post-heading" style={{position: 'absolute', top:'30%'}}>
                                    <h1 style={{color: 'white'}}><strong>3 tips to overcome the challenges while studying abr/oad.</strong></h1><span className="meta">Posted by&nbsp;<a href="/">EziExperience</a>&nbsp;on 10/05/2020</span></div>
                            </div>
                        </div>
                    </div>
                </header>

                <article id="txtpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <p>Have&nbsp; you/&nbsp; your&nbsp; kids&nbsp; thought&nbsp; of&nbsp; studying&nbsp; abroad?&nbsp; Wondering&nbsp; what&nbsp; are&nbsp; tips&nbsp; while studying abroad? You are on the right spot! There&nbsp; are&nbsp; so&nbsp; many&nbsp;
                                    benefits&nbsp; of&nbsp; studying&nbsp; abroad,&nbsp; including&nbsp; <strong>life&nbsp; experience enrichment,&nbsp; becoming&nbsp; more&nbsp; independent&nbsp; and&nbsp; open&nbsp; minded,&nbsp; English&nbsp; skills improvement</strong>,
                                    and many others, you name it</p>
                                <p>However, before enjoying these amazing perks, you are likely to face few challenges while studying overseas. All you need is&nbsp;to be prepared before&nbsp;your trip and deal with these challenges. The following ideas are here to
                                    support you!</p>
                                <p>However, before enjoying these amazing perks, you are likely to face few challenges while studying overseas. All you need is&nbsp;to be prepared before&nbsp;your trip and deal with these challenges. The following ideas are here to
                                    support you!</p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#737373'}}><strong>Challenge # 1 Feeling like being alienated</strong></h2>
                                <p>It&nbsp; could&nbsp; be&nbsp; tough&nbsp; at&nbsp; times&nbsp; when&nbsp; you&nbsp; have&nbsp; no&nbsp; relatives&nbsp; and&nbsp; friends&nbsp; in&nbsp; foreign countries. It feels lonely upon seeing others chatting together in student
                                    café while you eating alone. There are chances that you can’t get local’s inside joke but laugh embarrassingly&nbsp;&nbsp; to&nbsp; pretend&nbsp; you&nbsp; understand&nbsp; it.&nbsp; Sometimes&nbsp; you&nbsp; also&nbsp; get&nbsp;
                                    anxious,thinking&nbsp; if&nbsp; others&nbsp; are&nbsp; talking&nbsp; bad&nbsp; behind&nbsp; you.&nbsp; These&nbsp; scenarios&nbsp; sound&nbsp; frustrating right ? </p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#f8c828'}}><strong>How to handle it?</strong><br/></h2>
                                <p>Just&nbsp;step out and be more active to talk to your classmates and neighbours. Try to share some snacks/ dishes from your home country or introduce your own culture/lifestyle with them. This will be a great opportunity to let people
                                    know more about you&nbsp; and&nbsp; build&nbsp; up&nbsp; your&nbsp; social&nbsp; network.&nbsp; Always&nbsp; remember&nbsp; to&nbsp; be&nbsp; confident&nbsp; with yourself and SMILE to others! </p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#737373'}}><strong>Challenge # 2 Unfamiliar with local culture and lifestyle</strong><br/></h2>
                                <p>You may be surprised at local’s casual lifestyle that napping on the&nbsp;grass,&nbsp;strolling after&nbsp;dinner&nbsp; are&nbsp;just&nbsp;their&nbsp;day-to-day&nbsp; life.&nbsp;You&nbsp;may&nbsp;not&nbsp; get&nbsp; used&nbsp;to&nbsp;having&nbsp;br/ead/cereal
                                    for breakfast and burgers/ sandwiches for lunch. You may feel uncomfortable in parties and shy to dance and sing together. All these are so new to you that can lead to homesickness. </p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#65c6ce'}}><strong>How to handle it?</strong><br/></h2>
                                <p><strong>Gradually embrace the differences surrounding you.</strong> Although this could be hard at first, once you adopt accordingly and open up your mind, it will definitely be one of the most memorable life experience! Just roll
                                    with life! </p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#737373'}}><strong>Challenge # 3 Language barrier</strong><br/></h2>
                                <p>Fail to order a fancy delicacy and end up with having plain soup noodles? Feeling not confident&nbsp; with&nbsp; talking&nbsp; to&nbsp; stranger&nbsp; next&nbsp; to&nbsp; you&nbsp; so&nbsp; you&nbsp; keep&nbsp; playing&nbsp; boring&nbsp;
                                    mobile games?&nbsp; Getting&nbsp; lost&nbsp; in&nbsp; downtown&nbsp; area&nbsp; but&nbsp; doesn’t&nbsp; know&nbsp; how&nbsp; to&nbsp; reach&nbsp; out?Language barrier is very likely lead to these awkward situation. </p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#65c6ce'}}><strong>How to handle it?</strong><br/></h2>
                                <p>The&nbsp; more&nbsp; you&nbsp; practice,&nbsp; the&nbsp; more&nbsp; possible&nbsp; you&nbsp; gain&nbsp; fluent&nbsp; English&nbsp; skills.&nbsp; First&nbsp; and foremost, <strong>don’t worry about making mistakes</strong> because you
                                    actually learn more from mistakes. You can talk about common topics such as movie, food, and shows, etc., that is easy lead to pleasant conversation. </p>
                                <p>Additionally,&nbsp; when&nbsp;you&nbsp;talk&nbsp; to&nbsp;native&nbsp; speakers,&nbsp; try&nbsp; to&nbsp; <strong>remember&nbsp; the&nbsp; expressions, pronunciation and phrases they use</strong>. This will help you speak like a native!
                                    Don’t be shy to use body language as this can be a powerful tool to covey your ideas.</p>
                                <p>And lastly, <strong>jot down some potential phrases and questions on your notebook</strong>. When you can’t communicate smoothly under special circumstance, just show people your notes so you can get help easily.</p>
                                <p>The mentioned ideas are common challenges you may face. To overcome effectively, preparation&nbsp; before&nbsp; the&nbsp; trip&nbsp; is&nbsp; very&nbsp; important.&nbsp; This&nbsp; is&nbsp; when&nbsp; eziexperience’s&nbsp; service comes
                                    to play</p>
                                <p>We understand cultural difference, language barrier and lack of confidence are 3 major pain points while studying aboard. Our mission is to ease your dilemma. </p>
                                <p>We provide ultimate virtual homestay experience that allows you/ your kid to learn about local culture, lifestyle, manners and common phrases through interactive live video. You will go on the excursions to local café, zoo and even
                                    beach with the hosts to taste future destination.</p>
                                <p>How&nbsp; about&nbsp; language&nbsp; barriers?&nbsp; This&nbsp; can&nbsp; be&nbsp; your&nbsp; first&nbsp; concern&nbsp; right?&nbsp; Don’t&nbsp; worry about that, our hosts get your back!</p>
                                <p>Our&nbsp;host are qualified and experienced educators to teach you English skills along with various interesting topics. They will communicate with you in the simplest way.You can always ask questions , share opinions and get instant
                                    feedback right away. </p>
                                <p>All these activities will help you gain value experience and build confidence in your upcoming&nbsp; overseas&nbsp; studying&nbsp; trip.&nbsp; Sounds&nbsp; wonderful&nbsp; right?&nbsp; Guess&nbsp; what,&nbsp; we&nbsp; have more for
                                    you! </p>
                                <p>We also host parties for students so that you can expand your social network. Airport pick-up&nbsp; and&nbsp; free&nbsp; local&nbsp; support&nbsp; (hotline&nbsp; phone&nbsp; number)&nbsp; are&nbsp; also&nbsp; included&nbsp; in&nbsp;
                                    our package. Don’t miss our this opportunity! </p>
                                <p>You can book the free trail session to experience the service and reach out to EziExperience consultant anytime for more information. </p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default PostThree;