import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './contactUs.css';

import ico3 from '../../assets/img/ico3.png';
import learners from '../../assets/img/learners.png';
import Discover from '../../assets/img/Discover.png';
import Meet from '../../assets/img/Meet.png';

function Step2(props){
    
    var selectEHost = "card-block text-center radio";
    var selectStudent = "card-block text-center radio";
    var selectEducation = "card-block text-center radio";
    var selectPartnership = "card-block text-center radio";
    
    if(props.service2.includes("eHost")){
        selectEHost = "card-block text-center radio selected";
    }else{
        selectEHost = "card-block text-center radio";
    }

    if(props.service2.includes("Student")){
        selectStudent = "card-block text-center radio selected";
    }else{
        selectStudent = "card-block text-center radio";
    }

    if(props.service2.includes("Education Providers")){
        selectEducation = "card-block text-center radio selected";
    }else{
        selectEducation = "card-block text-center radio";
    }

    if(props.service2.includes("partnership")){
        selectPartnership = "card-block text-center radio selected";
    }else{
        selectPartnership = "card-block text-center radio";
    }

    function handlePartner(){
        props.handleServiceChange("service2", "partnership")
    }

    function handleEdu(){
        props.handleServiceChange("service2","Education Providers")
    }    
    
    function handleStudent(){
        props.handleServiceChange("service2", "Student")
    }

    function handleEHost(){
        props.handleServiceChange("service2", "eHost")
    }

    if (props.currentStep !== 2){
        return null;
    }

    return(
        <div>
            <div className="form-card" style={{marginTop: '1rem'}}>
                <h5 className="sub-heading">Select Service(s)</h5>
                <div className="row px-1 radio-group">
                    <div className={selectEHost} onClick={handleEHost}>
                        <div className="image-icon"> 
                            <img className="icon icon1" src={ico3} alt="" /> 
                        </div>
                        <p className="sub-desc">eHost</p>
                    </div>
                    <div className={selectStudent} onClick={handleStudent}>
                        <div className="image-icon"> 
                            <img className="icon icon1 fit-image" src={learners} alt="" /> 
                        </div>
                        <p className="sub-desc">Student</p>
                    </div>
                    <div className={selectEducation} onClick={handleEdu}>
                        <div className="image-icon"> 
                            <img className="icon icon1 fit-image" src={Discover} alt="" /> 
                        </div>
                        <p className="sub-desc">Education Providers</p>
                    </div>
                    <div className={selectPartnership} onClick={handlePartner}>
                        <div className="image-icon"> 
                            <img className="icon icon1 fit-image" src={Meet} alt="" /> 
                        </div>
                        <p className="sub-desc">Partnership</p>
                    </div>
                </div>
            </div>
        </div>
    )
    


}

export default Step2;