import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './blog.css';

class PostNine extends Component{
    render(){
        return(
            <div>
                 <header className="post_9" >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="post-heading">
                                    <h1><strong>Sample Interview Questions </strong><br/></h1><span className="meta">Posted by&nbsp;<a href="/">EziExperience</a>&nbsp;on 14/04/2021</span></div>
                            </div>
                        </div>
                    </div>
                </header>

                <article id="txtpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">

                                <p>Although you cannot predict what questions will be asked during the interview, you still need to practise answering sample interview questions to prevent yourself from getting tongue-tied. </p>
                                <p><strong>Traditional interview questions: </strong> </p>
                                <ul>
                                <li>Please briefly introduce yourself </li>
                                <li>What is your biggest strength?</li>
                                <li>What is your biggest flaw? (Tip: Show that you can overcome your flaws in your answer) </li>
                                <li>Why do you want to do an internship at our company?</li>
                                <li>What makes you a great fit for our company?</li>
                                <li>What do you hope to do next after completing this internship?</li>
                                </ul>

                                <p><strong>Behavioural interview questions: </strong> </p>
                                <ul>
                                <li>Tell me about a time when you had a conflict with a team member. How did you deal with that conflict?</li>
                                <li>How do you set objectives and fulfil them? Give an example.</li>
                                <li>Give an example of a time when you did more than what was expected of you for a task. </li>
                                <li>Tell me about a time when you considered the potential risks of a project and came up with a contingency plan.</li>
                                <li>Tell me about a time when you successfully persuaded someone to accept something you proposed. </li>
                                <li>How do you behave when working under stressful conditions? Give an example?</li>
                                </ul>

                                <h2 className="section-heading" style={{fontSize: '32px', color: '#65c6ce'}}><strong>Questions you can ask during the interview</strong><br/></h2>
                                <ul>
                                <li>What are the qualities that candidates should have to be successful in this position?</li>
                                <li>Can you describe the supervisor and team that I will be working with? </li>
                                <li>What objectives does your company want to achieve in the following 3 years?</li>
                                <li>Will there be any additional interviews after this? When will I hear back from you?</li>
                                </ul>
                                <p>The tips we have given you are not the only things you can do to ace interviews. Our online internship program comes with a free online
                                Professional Development program that helps bring your interview skills to the next level. Contact us through our website or email to find out more. </p>

                                <p style={{marginTop: '32px'}}><span> <a style={{color: '#37c8cf'}} href="/signUp">Sign up</a> </span> today to experience local culture and practise English with native speakers.  </p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default PostNine;