import React, { Component } from "react";

// import the icon library
import { library } from '@fortawesome/fontawesome-svg-core';
import {faPlayCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// to use bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './aboutUs.css';

//import image
import i_2 from '../../assets/img/i2.png';
import icon_2 from '../../assets/img/icon2.png';
import clico1 from '../../assets/img/clico1.svg';
import clico2 from '../../assets/img/clico2.svg';
import clico3 from '../../assets/img/clico3.svg';
import team_1 from '../../assets/img/team1.png';
import team_2 from '../../assets/img/team2.png';
import team_3 from '../../assets/img/team3.png';
import team_4 from '../../assets/img/team4.png';
import team_5 from '../../assets/img/RaviTeamPic.jpg';
import team_6  from '../../assets/img/SansaneeThongpond.png';
import team_7 from '../../assets/img/team7.jpeg';
import team_8 from '../../assets/img/team8.png';
import team_9 from '../../assets/img/team9.png';

library.add(
    faPlayCircle
    // more icon to go
);


class AboutUs extends Component {
    constructor(props){
        super(props);
        this.myRef = React.createRef();
    }

    render(){
        return(
            <div>
            {/* ====== Header ======  */}
                <header id="home" className="header aboutus" data-stellar-background-ratio="0.5">
                    <div className="header-overlay">
                    {/* header content */}
                        <div id="hdrtxt" className="caption v-middle text-center">
                            <h3 style={{color:'white', fontWeight:'400 !important'}}>EziExperience</h3>
                            <h1 style={{color:'white', fontWeight: '800 !important; padding: 0 20% 0 20%'}}><strong>Discover our humble beginings & who we are!</strong></h1>
                            <a className="vid" href="https://youtu.be/Dt-eW1KJLzc">
                            <div className="vid-play">
                                <FontAwesomeIcon className="fa-5x" icon={faPlayCircle} />
                            </div>
                            </a>
                        </div>
                    </div>
                </header>
                {/* ====== End Header ======  */}


                <div className="container" style={{paddingTop:'6rem'}}>
                    <div className="row">
                        <div className="col-md-10 col-lg-8 mx-auto">
                            <h1 style={{textAlign: 'center', fontSize: '64px', fontWeight: '600'}}><strong>Meet. Learn.
                                    Discover.&nbsp;</strong></h1>
                            <p style={{textAlign: 'center'}}>Intercultural is in the heart of our company. We understand the struggles
                                of students adapting
                                to living abroad because once, we were all them. After years of working closely
                                with international students and defining the challenges they have faced, EziExperience was born.
                                &nbsp;&nbsp; </p>
                        </div>
                    </div>
                </div>
                {/* Start: 1 Row 2 Columns */}

                <div className="container" style={{paddingBottom: '8rem'}}>
                    <div className="row d-xl-flex align-items-xl-center" style={{padding:'0 20% 0 20%'}}>
                        <div className="col-md-6" style={{textAlign: 'center'}}>
                            <img className="img-fluid" src={i_2} style={{textAlign: 'center'}} alt="img"/>

                        </div>
                        <div className="col-md-6">
                            <h1><strong>Mission</strong></h1>
                            <p>Our mission is to provide affordable access to the ultimate cultural immersion online experience to
                                anyone, anywhere.</p>
                        </div>

                    </div>

                    <div className="row d-xl-flex align-items-xl-center" style={{padding:'0 20% 0 20%'}}>
                        <div className="col-md-6" style={{textAlign: 'center'}}>
                            <img className="img-fluid" src={icon_2} style={{textAlign: 'center'}} alt="img"/>
                        </div>
                        <div className="col-md-6">
                            <h1><strong>Vision</strong></h1>
                            <p>To become a leading EdTech platform for innovative online experience, connecting people across
                                cultures with a wide variety of curated contents. </p>
                        </div>
                    </div>
                </div>

                {/* End: 1 Row 2 Columns */}

                {/* Start: Features Boxed */}
                <div className="features-boxed">
                    <div className="container">
                        {/* Start: Intro */}
                        <div className="intro">
                            <h1 style={{paddingTop:'5rem', fontSize: '56px !important', fontWeight: '800 !important'}}><strong>Core Values</strong></h1>
                        </div>
                        {/* End: Intro */}
                        {/* Start: Features */}
                        <div className="row justify-content-center features">
                            <div className="col-sm-6 col-md-5 col-lg-4 item">
                                <div className="box">
                                    <img src={clico1} style={{marginBottom: '46px', width: '200px'}} alt=""/>
                                    <h1 style={{fontSize: '32px'}}><strong>We are <br/> passionate</strong></h1>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-5 col-lg-4 item">
                                <div className="box">
                                    <img src={clico2} style={{marginBottom: '46px', width: '200px'}} alt=""/>
                                    <h1 style={{fontSize: '32px'}}><strong>We are <br/> diverse</strong></h1>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-5 col-lg-4 item">
                                <div className="box">
                                    <img src={clico3} style={{marginBottom: '46px', width: '200px'}} alt="" />
                                    <h1 style={{fontSize: '32px'}}><strong>We strive for <br/> innovation</strong></h1>
                                </div>
                            </div>

                        </div>
                        {/* End: Features */}
                    </div>
                </div>
                {/* End: Features Boxed */}
                {/* Start: Team Boxed */}
                <div id="team" className="team-boxed" style={{background: 'rgba(101,198,206,0.21)'}} ref={this.myRef}>
                    <div className="container">
                        {/* Start: Intro */}
                        <div className="intro">
                            <h1 className="text-center"
                                style={{paddingTop:'5rem', fontSize:' 56px !important', fontWeight: '800 !important'}}>Meet the Team
                            </h1>
                        </div>
                        {/* End: Intro */}
                        {/* Start: People */}
                        <div className="row people">
                            <div className="col-md-6 col-lg-4 d-xl-flex justify-content-xl-center item">
                                <div className="box" style={{width: '250pt'}}>
                                    <img className="rounded-circle" src={team_1} style={{height: '168px'}} alt=""/>
                                    <h3 className="name"><strong>Seyun</strong><br/><strong>Kim</strong><br/></h3>
                                    <p className="title">Founder<br/><strong>CEO</strong><br/></p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-xl-flex justify-content-xl-center item">
                                <div className="box" style={{width: '250pt'}}>
                                    <img className="rounded-circle" src={team_2} style={{height: '168px'}} alt=""/>
                                    <h3 className="name"><strong>Kittikarn&nbsp;</strong><br/><strong>Kon</strong><br/></h3>
                                    <p className="title"><strong>PRODUCT</strong><br/><strong>&nbsp;MANAGER</strong><br/></p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-xl-flex justify-content-xl-center item">
                                <div className="box" style={{width: '250pt'}}>
                                    <img className="rounded-circle" src={team_3} style={{height: '168px'}} alt=""/>
                                    <h3 className="name"><strong>MIWAKO </strong><br/><strong> LANGDON</strong><br/></h3>
                                    <p className="title"><strong>Sr. JAPANESE </strong><br/><strong> COORDINATOR</strong><br/></p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-xl-flex justify-content-xl-center item">
                                <div className="box" style={{width: '250pt'}}>
                                    <img className="rounded-circle"
                                        src={team_4} style={{height: '168px'}} alt=""/>
                                    <h3 className="name"><strong>Melinda </strong><br/><strong> Gonzalez</strong><br/></h3>
                                    <p className="title"><strong>CONTENTS&nbsp;</strong><br/><strong>DESIGNER</strong><br/></p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-xl-flex justify-content-xl-center item">
                                <div className="box" style={{width: '250pt'}}>
                                    <img className="rounded-circle"
                                        src={team_5} style={{height: '168px'}} alt=""/>
                                    <h3 className="name"><strong>RaviTeja&nbsp;</strong><br/><strong>Duggineni</strong><br/></h3>
                                    <p className="title">Product&nbsp;<br/><strong>DEVELOPER</strong><br/></p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 d-xl-flex justify-content-xl-center item">
                                <div className="box" style={{width: '250pt'}}>
                                    <img className="rounded-circle"
                                        src={team_6}
                                        style={{height: '168px'}} alt=""/>
                                    <h3 className="name"><strong>Sansanee&nbsp;</strong><br/><strong>Thongpond</strong><br/></h3>
                                    <p className="title">Project&nbsp;<br/><strong>COORDINATOR</strong><br/></p>
                                </div>
                            </div>
                            
                            <div className="col-md-6 col-lg-4 d-xl-flex justify-content-xl-center item">
                                <div className="box" style={{width: '250pt'}}>
                                    <img className="rounded-circle"
                                        src={team_7}
                                        style={{height: '168px', width:'151px'}} alt=""/>
                                    <h3 className="name"><strong>Annie</strong><br/><strong>Liu</strong><br/></h3>
                                    <p className="title">&nbsp;<br/><strong>Growth Specialist - China</strong><br/></p>
                                </div>
                            </div>
                            
                            <div className="col-md-6 col-lg-4 d-xl-flex justify-content-xl-center item">
                                <div className="box" style={{width: '250pt'}}>
                                    <img className="rounded-circle"
                                        src={team_8}
                                        style={{height: '168px'}} alt=""/>
                                    <h3 className="name"><strong>Nirel&nbsp;</strong><br/><strong>Hardjosewoyo</strong><br/></h3>
                                    <p className="title">&nbsp;<br/><strong>Growth Specialist - Indonesia</strong><br/></p>
                                </div>
                            </div>
                            
                            <div className="col-md-6 col-lg-4 d-xl-flex justify-content-xl-center item">
                                <div className="box" style={{width: '250pt'}}>
                                    <img className="rounded-circle"
                                        src={team_9}
                                        style={{height: '168px'}} alt=""/>
                                    <h3 className="name"><strong>Rey&nbsp;</strong><br/><strong>Nguyen</strong><br/></h3>
                                    <p className="title">&nbsp;<br/><strong>Growth Specialist - Vietnam</strong><br/></p>
                                </div>
                            </div>
                            
                            
                            
                        </div>
                        {/* End: People */}
                    </div>
                </div>
                {/* End: Team Boxed */}
            </div>
        )
    }
}

export default AboutUs;