import React, {useRef, useEffect} from 'react';
import { Background, Parallax } from 'react-parallax';
import OwlCarousel from 'react-owl-carousel'; 
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Slider from "react-slick";

// import the icon library
import { library } from '@fortawesome/fontawesome-svg-core';
import {faUniversity, faFilm, faSmile, faGraduationCap, faAngleDown, faPlayCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import '../../assets/css/Video-Parallax-Background-v2.css';
import './landingpage.css';
import '../../assets/css/responsive.css';

// import image
import BackgroundImg from '../../assets/img/plcHdr.png';
import Logo from '../../assets/img/EziExpLogo.svg';
import subHead from '../../assets/img/subHead.png';
import happyFamily from '../../assets/img/happy-family-png';
import asianGirl from "../../assets/img/asian-girl-.png";
import meet from "../../assets/img/Meet.png";
import learn from "../../assets/img/Learn.png";
import discover from "../../assets/img/Discover.png";
import mh from "../../assets/img/mh.png";
import hr from "../../assets/img/hr.png";
import tw from "../../assets/img/tw.png";
import ns from "../../assets/img/ns.png";
import team from "../../assets/img/team.png";
import follower from "../../assets/img/follower.png";
import complain from "../../assets/img/complain.png";
import SMEAG from "../../assets/img/1.png";
import MEIHO from "../../assets/img/2.png";
import SHANE from "../../assets/img/3.png";
import Tamagawa from "../../assets/img/4.png";
import iss from "../../assets/img/5.png";
import SWINBURNE from "../../assets/img/6.png";
import studymelbourne from "../../assets/img/7.png";
import JTB from "../../assets/img/8.png";
import Ronghuai from "../../assets/img/9.png";
import DMM from "../../assets/img/10.png";
import WISH from "../../assets/img/11.png";
import VictoriaUni from "../../assets/img/12.png";
import AussieGOV from "../../assets/img/13.jfif";
import NEAS from "../../assets/img/14.png";
import KTV from "../../assets/img/ktv.PNG";
import Explore from '../../assets/img/explore.png';

// import video
import hmVideo from '../../assets/video/ezihmVid.mp4';
import intVid from "../../assets/video/intVid.mp4";
import Mhen from "../../assets/video/MHen.mp4";

library.add(
    faUniversity, 
    faFilm, 
    faSmile, 
    faGraduationCap,
    faAngleDown,
    faPlayCircle
    // more icon to go
);

function Landing(){
    var curIdx = 0;
    const refVideo0 = useRef(null);
    const refVideo1 = useRef(null);

    useEffect(() => {

    })

    const handleCarouselChange = e => {
        if(curIdx !== e.property.value && typeof(e.property.value) !== "object"){
            curIdx = e.property.value;
        
            if(curIdx === 1){
                refVideo0.current.defaultMuted = true;
                refVideo0.current.muted = true;
            } else if (curIdx === 2){
                refVideo1.current.defaultMuted = true;
                refVideo1.current.muted = true;
                refVideo0.current.defaultMuted = true;
                refVideo0.current.muted = true;
            } else {
                refVideo1.current.defaultMuted = true;
                refVideo1.current.muted = true;
            }
        }

    }
  
    return(
        <div>
            <section id="hero" style={{width: '100%'}}>
            {/*-- Start: Video Parallax Background v2 */}
                <div className="parallax-container" >
                <Parallax 
                    style={{backgroundSize: 'cover', backgroundPosition: 'center', backgroundAttachment: 'fixed', position: 'relative'}}
                >
                    <div className="container d-flex justify-content-center align-items-center parallax-content" style={{height: '100vh', backgroundImage: BackgroundImg}}>
                        <div className="col-12 col-md-10 col-lg-8 d-flex justify-content-center align-content-center flex-column">
                                <div className="d-flex justify-content-center">
                                <h1 style={{width: '200px', alignSelf: 'center'}}>
                                    <img src={Logo} alt="img"/>
                                </h1>
                            </div>

                            <div>
                                <br/><img className="img-fluid" src={subHead} alt="img"/>
                            </div>
                            <h3 style={{ color: 'white', textAlign: 'center', fontWeight: '600 !important'}}>The ultimate virtual experience of living abroad<br/></h3>
                        </div>
                    </div>
                    <Background>
                        <video className="parallax-background" autoPlay loop muted><source src={hmVideo} type="video/mp4" wp-acf="[{'type':'url','name':'video','label':'Video','wrapper':{'width':25}},{'type':'text','name':'video_css','label':'Video CSS (eg. filters)','wrapper':{'width':25}}]" wp-attr="[{'target':'src','replace':'%1'},{'target':'parent_style','replace':'%2'}]" /></video>
                    </Background>
                </Parallax>
                </div>
            </section>
            {/*-- Choose area  Start */}
            <div className="choose-area" style={{zoom: '80% !important'}}>
                <div className="container">
                    <div className="row">
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-6" >
                            <div className="choose-blk">
                                <div className="choose-thumb">
                                    <img src={happyFamily} alt="" />
                                </div>
                                <div className="choose-content">
                                    <h3 style={{paddingTop: '3rem', fontWeight: '600 !important'}}>Immersive <br/>Online Homestay</h3>
                                    <h4 style={{lineHeight: '5rem'}}>Meet. Learn. Discover</h4>
                                    <p style={{lineHeight: '2.5rem'}}>Experience immersive living abroad without the travel and hefty costs.
                                    Practise communicating in English with a native speaker through content integrated learning.</p>
                                    <div className="choose-btn">
                                        <a href="/homestay">Explore</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="choose-blk">
                                <div className="choose-thumb">
                                    <img src={asianGirl} alt="" />
                                </div>
                                <div className="choose-content">
                                    <h3 className="color-fr" style={{paddingTop: '3rem', fontWeight: '600 !important'}}>Empowering<br/>Online Internship</h3>
                                    <h4 style={{lineHeight: '5rem'}}>Real Work. Real World Experience<br/> </h4>
                                    <p sstyle={{lineHeight: '2.5rem'}}>Gain world-className, international work experience from our vast network of Australian companies.
                                    Make yourself familiar with the Australian workplace before comming to Australia.</p>
                                    <div className="choose-btn color-btn">
                                        <a href="/internship">Explore</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*-- Choose area  End */}

            {/*-- Why area Start */}
            <section className="norm">
                <div className="why-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h2>Experience the new normal</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-1">
                            <div className="col-lg-4 col-md-4">
                                <div className="why-wrapper-blk text-center">
                                    <div className="why-thumb">
                                        <img className="img-fluid" src={meet} alt="" />
                                    </div>
                                    <div className="why-content">
                                        <h3 style={{fontWeight: '600 !important'}}>Meet</h3>

                                        <div className="why-inner">
                                            <h5 style={{color: 'white'}}>100+ Ehosts <br/> & companies</h5>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4">
                                <div className="why-wrapper-blk text-center">
                                    <div className="why-thumb">
                                        <img src={learn} alt="" />
                                    </div>
                                    <div className="why-content">
                                        <h3 className="color-tr" style={{fontWeight: '600 !important'}}>Learn</h3>

                                        <div className="why-inner">
                                            <h5 style={{color: 'white'}}>3000 + Sessions & <br/> placements</h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <div className="why-wrapper-blk text-center">
                                    <div className="why-thumb">
                                        <img src={discover} alt="" />
                                    </div>
                                    <div className="why-content">
                                        <h3 className="color-disd" style={{fontWeight: '600 !important'}}>Discover</h3>
                                        <div className="why-inner">
                                            <h5 style={{color: 'white'}}>25+ Cities<br/>Explore to discover</h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*-- Why area End */}


            {/*-- Slider area Start */}
            <div className="sider-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb-50">
                                <h3 style={{color: 'white', fontSize: '46pt !important'}}>Our alumnus says...</h3>
                            </div>
                        </div>
                    </div>

                    <OwlCarousel className="slider-active" items={1} rewind autoPlay={true} margin={10} nav={true} video={true} onChange={handleCarouselChange} >
                        <div className="item" style={{backgroundColor: 'grey'}}>
                            <div className="row align-items-center">
                                <div className="col-lg-8">
                                    <div className="video-wrapper">
                                        <video autoPlay controls muted loop ref={refVideo0} id="myVideo">
                                            <source src={Mhen} type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="slider-content">
                                        <h4>Yoshio Kamakura</h4>
                                        <h5>Meiho Jr & Sr High School</h5>
                                        <p>The hosts are so friendly! They taught me a lot of new English words through different activities</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="item" style={{backgroundColor: 'grey'}}>
                            <div className="row align-items-center">
                                <div className="col-lg-8">
                                    <div className="video-wrapper">
                                        <video autoPlay controls muted loop ref={refVideo1} id="myVideo">
                                            <source src={intVid} type="video/mp4"/>
                                        </video>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="slider-content">
                                        <h4>Venice Ang</h4>
                                        <h5>University of Melbourne</h5>
                                        <p>EziExperience Online Internship guides me to be an effective and collaborative remote worker.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="item" style={{backgroundColor: 'grey'}}>
                            <div className="row align-items-center">
                                <div className="col-lg-8">
                                    <div className="video-wrapper" onClick={() => window.open("https://www.youtube.com/embed/BWvJoHmdBNo?enablejsapi=1")}>
                                        <img style={{objectFit: 'cover'}} src={KTV} alt="" />
                            
                                        <div className="vid-play v-middle text-center">
                                            <FontAwesomeIcon className="fa-5x" icon={faPlayCircle} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="slider-content">
                                        <h4>Seyun Kim</h4>
                                        <h5>Founder and CEO of EziExperience</h5>
                                        <p>The launch of EziExperience's virtual homestay programme has changed the Edtech market. Here's the latest scoop from KTV National Broadcasting.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </OwlCarousel>
                </div>
            </div>
            {/*-- Slider area End */}



            {/*--====== Numbers ======*/}
            <section className="numbers" >

                <div className="container" style={{paddingBottom: '3rem'}}>
                    <div className="row text-center justify-content-center">
                        <h2 style={{color: 'white', padding: '0 0 6rem 0'}}>Accredited by <a href="https://neas.org.au/quality-assurance/" style={{color: '#FFC600'}}><strong>NEAS</strong></a> for superior quality</h2>
                    </div>

                    <div className="row text-center justify-content-center">

                        {/*-- number items */}
                        <div className="col-md-3 col-sm-6">
                            <div className="numb-item">
                                <FontAwesomeIcon className="fa-10x" icon={faFilm} />
                                <h3 className="num" style={{color: '#FFC600'}}>3000</h3>
                                <h4 style={{color: 'white'}}>SESSIONS SOLD </h4>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-6">
                            <div className="numb-item">
                                <FontAwesomeIcon className="fa-10x" icon={faSmile} />
                                <h3 className="num" style={{color: '#37C8CF'}}>97 </h3>
                                <h4 style={{color: 'white'}}>&#37; SATISFACTION </h4>
                            </div>
                        </div>

                        <div className="col-md-3 col-sm-6">
                            <div className="numb-item">
                                <FontAwesomeIcon className="fa-10x" icon={faUniversity} />
                                <h3 className="num" style={{color: '#F595BA'}}>20</h3>
                                <h4 style={{color: 'white'}}>SCHOOLS</h4>
                            </div>
                        </div>
                        
                        <div className="col-md-3 col-sm-6">
                            <div className="numb-item">
                                <FontAwesomeIcon className="fa-10x" icon={faGraduationCap} />
                                <h3 className="num">500</h3>
                                <h4 style={{color: 'white'}}>STUDENTS</h4>
                            </div>
                        </div>
                    </div>
                
                    <div className="container process section-padding" style={{paddingTop: '3rem'}}>
                        <div className="row">

                            {/*-- items */}
                            <div className="col-md-3 col-sm-6 ">
                                <div className="items text-center ">
                                <div className="item-content">
                                    <h5><a href="/"><img style={{width: '150px'}} src={mh} alt="" /></a></h5>
                                    <h6 style={{fontSize: '20px', color: 'ghostwhite'}}> MEIHO <br/>HIGH SCHOOL</h6>
                                    <p style={{fontSize: '20px'}}>96 sessions sold with 90% satisfaction</p>
                                </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="items text-center">
                                <div className="item-content">
                                    <h5><a href="/"><img style={{width: '150px'}} src={hr} alt=""/></a></h5>
                                    <h6 style={{fontSize: '20px', color: 'ghostwhite'}}>HIROSHIMA <br/>SHUDO UNIVERSITY</h6>
                                    <p style={{fontSize: '20px'}}>Immersive Online Homestay Experience</p>
                                </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="items text-center">
                                <div className="item-content">
                                    <h5><a href="/"><img style={{width: '150px'}} src={tw} alt="" /></a></h5>
                                    <h6 style={{fontSize: '20px', color: 'ghostwhite'}}>TAMAGAWA <br/>UNIVERSITY</h6>
                                    <p style={{fontSize: '20px'}}>1,187 sessions sold & Online Homestay Program.</p>
                                </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6">
                                <div className="items text-center">
                                <div className="item-content">
                                    <h5><a href="/"><img style={{width: '150px'}} src={ns} alt="" /></a></h5>
                                    <h6 style={{fontSize: '20px', color: 'ghostwhite'}}>NEAS  </h6>
                                    <p style={{fontSize: '16px'}}> <br/> Accredited by NEAS for our <br/>superior Online Programs.</p>
                                </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/*--====== End Numbers ======*/}


            {/*-- ====== Video ======  */}
            <div className="serv-us">
                <div className="container" style={{paddingLeft: '5%'}}>
                    <div className="student-title">
                        <h4 style={{textAlign: 'left', fontSize: '42pt', fontWeight: '600 !important', color: 'grey'}}><strong>Are you ...</strong></h4>
                    </div>

                    <div className="row">
                        <div className="col-md-6" style={{float: 'left'}}>
                            <Accordion defaultActiveKey="0">
                                
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0" style={{height: '5vh'}}>
                                        <h6 style={{color: '#ffc600', fontWeight: '400 !important', textAlign: "left", textDecoration:'underline'}}>
                                            Students & Graduates {' '}
                                            <FontAwesomeIcon icon={faAngleDown} />
                                        </h6>               
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <p style={{color: 'black', textAlign: "left", fontSize: '15px'}}>Stand out. Feel confident in taking your next step with our programs designed to make you succeed.</p>
                                        <div className="choose-btn" style={{fontWeight: '500'}}>
                                        <a href="/signUp">Enquire Now </a>
                                        </div>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card style={{ border: 0 }}>
                                    <Accordion.Toggle as={Card.Header} eventKey="1">
                                        <h6 style={{color: '#37c8cf', fontWeight: '400 !important',textAlign: "left", textDecoration:'underline'}}>
                                            Education Providers {' '}
                                            <FontAwesomeIcon icon={faAngleDown} />
                                        </h6>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <p style={{color: 'black', textAlign: "left", fontSize: '15px'}}>Stand out. Feel confident in taking your next step with our programs designed to make you succeed.</p>
                                        <div className="choose-btn" style={{fontWeight: '500'}}>
                                        <a href="/contact">Enquire Now </a>
                                        </div>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card style={{ border: 0 }}> 
                                    <Accordion.Toggle as={Card.Header} eventKey="2" >
                                        <h6 style={{color: '#ff80b1', fontWeight: '400 !important', textAlign: "left", textDecoration:'underline'}}>
                                            Host families & Companies {' '}
                                            <FontAwesomeIcon icon={faAngleDown} />
                                        </h6>
                
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <p style={{color: 'black', textAlign: "left", fontSize: '15px'}}>Discover the newest up and coming talents and personalities. Give opportunities to promising students who need you.</p>
                                        <div className="choose-btn" style={{fontWeight: "500"}}>
                                            <a href="/contact">Enquire Now </a>
                                        </div>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                            </Accordion>
                        </div>

                        <div className="col-md-6" style={{borderRadius:"10rem", border:"2px solid white", float:'left'}}>
                            <div className="our-video"  onClick={() => window.open("https://youtu.be/AAJASc9shdg")}>
                                <img style={{objectFit: 'cover'}} src={Explore} alt="" />
                                <div className="vid-play v-middle text-center">
                                    <FontAwesomeIcon className="fa-5x" icon={faPlayCircle} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/*-- our video */}


            </div>
        {/*-- ====== End Video ======  */}

        {/*-- ====== hero ======  */}
        <section id="about" className="hero section-padding" >
            <div className="container" style={{padding: "2.5% !important"}}>
                <div className="Engage-title text-center">
                    <h3 style={{color: 'white'}}>Engage With Us</h3>
                </div>

            <div className="row">

                {/*-- hero-item */}
                <div className="hero-item col-md-4 text-center meet">
                    <span><img style={{padding: '15%', hover: 'border:1px solid yellow'}} src={team} alt="" /></span>
                    <div className="hero-item-content">
                        <h5>Meet.</h5>

                        <div className="choose-btn" style={{fontWeight: '500'}}>
                            <a href="/aboutUs">About Us</a>
                        </div>
                    </div>
                </div>

                <div className="hero-item col-md-4 text-center learn ">
                    <span><img style={{padding: '15%'}} src={follower} alt="" /></span>
                    <div className="hero-item-content">
                        <h5>Learn.</h5>

                        <div className="choose-btn" style={{fontWeight: '500'}}>
                            <a href="https://www.instagram.com/eziexperience/">Our Insta</a>
                        </div>
                    </div>
                </div>

                <div className="hero-item col-md-4 text-center discover">
                    <span><img style={{padding: '15%'}} src={complain} alt="" /></span>
                    <div className="hero-item-content">
                        <h5>Discover.</h5>

                        <div className="choose-btn" style={{fontWeight: '500'}}>
                            <a href="/contact">Enquire Now</a>
                        </div>

                    </div>
                </div>

                <div className="clear-fix"></div>

                </div>
            </div>
        </section>
        {/*-- ====== End hero ======  */}

        <div className="container">
            <div className="Engage-title text-center" style={{paddingTop: '5rem'}}>
                <h3 style={{color: '#f288b1'}}>Clients & Supports</h3>
            </div>
            <Slider  dots = {false} slidesToShow={6} slidesToScroll= {1} autoplay={true} autoplaySpeed={1500}>
                <div className="slide"><a href="http://www.smeag.com"><img className="img-fluid" src={SMEAG} alt=""/></a></div>
                <div className="slide"><a href="https://www.meiho.ed.jp"><img className="img-fluid" src={MEIHO} alt=""/></a></div>
                <div className="slide"><a href="https://shaneschools.com/en/"><img className="img-fluid" src={SHANE} alt=""/></a></div>
                <div className="slide"><a href="https://www.tamagawa.jp"><img className="img-fluid" src={Tamagawa} alt=""/></a></div>
                <div className="slide"><a href="https://www.iss-ryugakulife.com"><img className="img-fluid" src={iss} alt=""/></a></div>
                <div className="slide"><a href="https://www.swinburne.edu.au"><img className="img-fluid" src={SWINBURNE} alt=""/></a></div>
                <div className="slide"><a href="https://www.studymelbourne.vic.gov.au"><img className="img-fluid" src={studymelbourne}alt=""/></a></div>
            </Slider>

            <Slider dots = {false} slidesToShow={6} slidesToScroll= {1} autoplay={true} autoplaySpeed={1500}>
                <div className="slide"><a href="https://www.jtbcorp.jp/en/"><img className="img-fluid" src={JTB} alt=""/></a></div>
                <div className="slide"><a href="http://studyronghuaischool.com/"><img className="img-fluid" src={Ronghuai} alt=""/></a></div>
                <div className="slide"><a href="https://www.dmm.com"><img className="img-fluid" src={DMM} alt=""/></a></div>
                <div className="slide"><a href="http://www.wish.co.jp"><img className="img-fluid" src={WISH} alt=""/></a></div>
                <div className="slide"><a href="https://www.vu.edu.au"><img className="img-fluid" src={VictoriaUni} alt=""/></a></div>
                <div className="slide"><a href="https://www.austrade.gov.au"><img className="img-fluid" src={AussieGOV} alt=""/></a></div>
                <div className="slide"><a href="https://neas.org.au"><img className="img-fluid" src={NEAS} alt=""/></a></div>
            </Slider>
        </div>




        </div>
    )

}

export default Landing;