import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './blog.css';

import blogImg_1 from '../../assets/img/blog5img1.png';
import blogImg_2 from '../../assets/img/blog5img2.png';
import blogImg_3 from '../../assets/img/blog5img3.png';
import blogImg_4 from '../../assets/img/blog5img4.png';
import blogImg_5 from '../../assets/img/blog5img5.png';


class PostFive extends Component{
    render(){
        return(
            <div>
                <header className="post_5">
                        <div className="container">
                            <div className="row" style={{height:"80vh"}}>
                                <div className="col-md-10 col-lg-8 mx-auto">
                                    <div className="post-heading" style={{position: 'absolute', top:'30%'}}>
                                        <h1 style={{color: 'white'}}><strong>Are Virtual Internship Programs Worth It?</strong></h1>
                                        <h4>Here are 5 benefits of doing an online overseas internship </h4>
                                        <span className="meta">Posted by&nbsp;<a href="/">EziExiperience</a>&nbsp;on 24 / 03, 2021</span></div>
                                </div>
                            </div>
                        </div>
                    </header>

                <article id="txtpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                            <blockquote className="blockquote">
                                <p className="mb-0"><em>I dreamed of travelling abroad to gain international work experience once I finish university, but then the pandemic hit and crushed this dream of mine.</em></p>
                            </blockquote>

                                <p>Many international university students, fresh graduates and job seekers wishing to work abroad share this same feeling of loss.
                                Right now, they either have to wait until it is safe to travel or do an online overseas internship. But are remote internships worth doing?
                                Well, as someone who did an online internship after finishing university, I can assure you that online overseas internships are worth it.
                                They have many benefits that are crucial for you to achieve career success. In this article, we look at five of these benefits. </p>


                                <h2 className="section-heading" style={{fontSize: '32px', color: '#f8c828'}}><strong>Benefit #1 Learn a different culture and language at minimal costs</strong><br/></h2>
                                <img className="img-fluid" src={blogImg_1} alt="" />
                                <p>Overseas internships are great for learning different cultures and languages in addition to gaining work experience. Indeed, in an increasingly globalised world, you will likely need to communicate with people overseas for work,
                                so it’s necessary to build up your cultural and linguistic knowledge through an overseas internship.  </p>
                                <p>But why is an ONLINE overseas internship as good as, or better than, traditional overseas internships? The answer is the costs. Unlike traditional overseas internships, online overseas internships require no extra expenses for flights, meals,
                                    transportation, accommodation and other essentials. You only need a computer to explore a different culture and practise a new language. </p>


                                <h2 className="section-heading" style={{fontSize: '32px', color: '#f8c828'}}><strong>Benefit #2 Prepare for future online tasks or jobs with enhanced digital skills</strong><br/></h2>
                                <img className="img-fluid" src={blogImg_2} alt="" />
                                <p>The COVID-19 lockdowns sparked a rise in remote working, so you never know when your dream job is going to transfer to an online environment as well.
                                If you’re no tech expert, don’t worry, because you can absolutely prepare for the digitisation of work through an online internship. </p>
                                <p>Unlike in-person internships, online internships require you to communicate with your team using digital programs.
                                I, for example, had to use Trello and Lark to collaborate with my co-workers (I learned how to use these apps under the guidance of EziExperience company).
                                As such, online internships push you to acquire the digital skills needed for future online tasks. Additionally, you can list those digital skills in your resume to impress future employers. </p>


                                <h2 className="section-heading" style={{fontSize: '32px', color: '#f8c828'}}><strong>Benefit #3 Grow professional network internationally</strong><br/></h2>
                                <img className="img-fluid" src={blogImg_3} alt="" />
                                <p>As you’ll be working remotely, you can connect and work with professionals around the world. This then allows you to expand your global professional network.
                                If you are considering starting a career overseas, it will be even more important for you to have an established network of professionals in your target destination.  </p>


                                <h2 className="section-heading" style={{fontSize: '32px', color: '#f8c828'}}><strong>Benefit #4 Flexible schedule and workplace setting</strong><br/></h2>
                                <img className="img-fluid" src={blogImg_4} alt="" />
                                <p>Unlike in-person internships, online internships have a more flexible work schedule and workplace location. Flexible working can help improve your productivity.
                                Perhaps you are most productive when you work at a quiet cafe with a fresh cup of coffee. We all have different needs. Online internships’ flexibility accommodates those needs. </p>

                                <h2 className="section-heading" style={{fontSize: '32px', color: '#f8c828'}}><strong>Benefit #5 Build self-sufficiency and become more organised</strong><br/></h2>
                                <img className="img-fluid" src={blogImg_5} alt="" />
                                <p>Online internships help you develop self-sufficiency, which is an important attribute to have in the workplace. While I was doing my online internship,
                                I researched how to do the assigned tasks myself and managed to solve problems myself.
                                Being independent gives you great satisfaction and shows your employer that you are a dependable employee. </p>
                                <p>Apart from developing self-reliance, online internships also teach you how to be more organised with your time because nobody is monitoring you while you work remotely.
                                Good time management skills are something you would need for any industry. </p>
                                <p>These are the five benefits of doing an online overseas internship. However, there are more things you can do to enrich your internship experience and achieve long-term career success.
                                You can join career coaching and social networking programs, which are important
                                for your long-term career success because you can gain life-long knowledge in how to get hired and obtain additional career opportunities from people outside of your hosting company. </p>
                                <p>Online overseas internships have many advantages and are not simply convenient substitutes for traditional internships, so don’t hesitate to give them a try! </p>

                                <p>If you’re interested in doing an online internship, check out <a href="https://www.eziexperience.co">our Australian-based online internship programs</a>. Our 5 to 12-week online internships are packaged with free Intercultural
                                    and Professional Development programs designed to enrich your internship experience with the career coaching and social networking services I mentioned above. </p>


                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default PostFive;