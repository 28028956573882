import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './blog.css';

import post10_1 from '../../assets/img/post10_1.png';
import post10_2 from '../../assets/img/post10_2.jpeg';
//import post10_3 from '../../assets/img/post10_3.jpeg';

class PostTen extends Component{
    render(){
        return(
            <div>
                 <header className="post_10">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="post-heading">
                                    <h1 style={{color: "white"}}><strong>Has Covid19 brought changes to your business?</strong><br/></h1><span className="meta">Posted by&nbsp;<a href="/">EziExperience</a>&nbsp;on 02/06/2021</span></div>
                            </div>
                        </div>
                    </div>
                </header>
                <article id="txtpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <p style={{margin: '20px 0 20px 0'}}>
                                Seyun Kim, Founder and CEO of EziExperience, was recently interviewed by a KTV National Broadcasting programme. 
                                </p>

                                <p style={{margin: '0 0 20px 0'}}>
                                    Seyun Kim, also known as Yun,said “I teamed up with my team to launch Eziexperience's virtual homestay programme 
                                    which has been a game changer in the EdTech market”
                                </p>

                                <p style={{margin: '0 0 20px 0'}}>
                                    Following the Covid19 pandemic in August 2020, Ozihouse group has shifted its emphasis to EziTech, an edtech business
                                     that provides services ranging from online homestay experiences to online internship programmes under the 
                                     EziExperience brand.
                                </p>

                                <p style={{margin: '0 0 20px 0'}}>
                                    In just five months, EziExperiece's online homestay and lifestyle programme has conducted over 3,000 sessions to 500 
                                    students from Japan, China, Korea, and Thailand.
                                </p>

                                <p style={{margin: '0 0 20px 0'}}>
                                    The program's goal is to associate international students with Australian residents. This will benefit the students as 
                                    they can learn about Australian culture while also improving their English speaking skills.  In comparison to a traditional 
                                    English course, our program’s curriculum is very engaging in terms of learning and communications.The learner will know how 
                                    the host lives in the destination and will demonstrate what life is like in Australia on a daily basis..
                                </p>

                                <p style={{margin: '0 0 20px 0'}}>
                                    <strong>Seyun Kim, Founder and CEO of EziExperience said </strong> “Our online homestay and lifestyle programmes are more than just English lessons; 
                                    they are a way of life. Learners will have the ability to interact and converse with local hosts in real time. Our hosts will 
                                    introduce you to their family members, take you on a walk around the neighbourhood, or take you to cafes, the zoo, 
                                    or museums – a unique experience that will give you a taste of life in Australia without the travel and hefty costs”
                                </p>

                                <p style={{margin: '0 0 20px 0'}}>
                                    “Our curriculum is more of a socialisation session than a learning session, and it takes you to beautiful places in Australia 
                                    that you wouldn't find in a typical English class. Via content integrated learning, our e-hosts will provide the interactive experience 
                                    while learners practise interacting in English with a native speaker..”
                                </p>

                                <p>
                                    Jessica Shaw , E-host of EziExperience said “The session is not learning back and forth to teach you this word, you listen and you get tested. 
                                    It's more just like a conversation and we're learning how to use words correctly. And I believe that this will ensure that students will have 
                                    the opportunity to experience it and engage with it."
                                </p>

                                <img className="img-fluid" src={post10_2} alt=""/>

                                <p>
                                    Jessica was demonstrating the online homestay and lifestyle session to international students on the day of the interview. 
                                    She began by introducing her family members and giving tours of the house. During the session, learners and hosts interacted 
                                    by asking each other questions and learning more about each other's lives.
                                </p>

                                <img className="img-fluid" src={post10_1} alt=""/>
                                
                                <p>
                                    Done with the house tour? Don't worry, our hosts will show you around their neighbourhood, including beautiful cafes and 
                                    incredible sights. Learners will be able to see what Australian suburbs look like and see how locals interact in the area. 
                                    Learners will receive immersive e-Learning while getting a taste of Australia. 
                                </p>

                                <p>
                                    EziExperience also offers an online Internship programme that allows you to work on real projects and gain real experience 
                                    with an Australian organisation, while also expanding your professional network.
                                </p>

                                <p>
                                    If you have any questions about what EziExperience has to offer, please email us at  eziexperience@ezitech.co  
                                    or create an account on our official website  today to start learning about Australian local culture and practise 
                                    English with our native speakers.
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default PostTen;