import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './blog.css';

class PostSix extends Component{
    render(){
        return(
            <div>
                <header className="post_6">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 col-lg-8 mx-auto">
                                    <div className="post-heading">
                                        <h1 style={{color: 'white'}}><strong>3 Reasons Why You Should Join Eziexperience’s Online Internship Program</strong><br/></h1><span className="meta">Posted by&nbsp;<a href="/">EziExperience</a>&nbsp;on 4/03/2021</span></div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <article id="txtpg">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 col-lg-8 mx-auto">
                                    <p style={{margin: '0 0 20px 0'}}>Are you an international university student, graduate or job seeker who wants to do an internship in Australia?<br/><br/>Do you dream about gaining some work experience and learning about the culture of Australia?<br/><br/>Well, Eziexperience can make your dream come true even during a global pandemic! We have a 5 to 12-week Online Internship program where you can do practical work for an Australian company, but from the comfort of your own homes! Not sure if our Online Internship program is worth a try? Here are three reasons why you should join us. </p>
                                    <h2 className="section-heading" style={{fontSize: '32px', color: '#f8c828'}}><strong>Reason #1 MEET local working professionals and grow your professional network globally </strong><br/></h2>
                                    <p>The more you connect, the more you grow!<br/><br/>Our program allows you to grow your global professional network because you will meet Australian working professionals during your internship. You will also be able to expand your global professional network through our free Professional Development Program, as it enables you to workshop your employability skills with local CEOs and get useful career-building advice from migrant entrepreneurs. <br/><br/>It’s important that you network with professionals from around the world to widen your cultural competence. As you never know when you will need jobs from a foreign country, it is always good to know foreign working professionals who can offer the overseas job opportunities you crave for. 
                </p>
                                    <h2 className="section-heading" style={{fontSize: '32px', color: '#65c6ce'}}><strong>Reason #2 LEARN time management, problem solving, and the intricacies of your dream industry</strong><br/></h2>
                                    <p>The more you do, the more you learn!<br/> <br/>When completing our online internship program, you will be responsible for working on a real practical project, developing from concept to execution, for a real company by a set deadline.<br/><br/>By the end of your internship, you will gain real work experience, develop good time management skills and enhance your problem-solving skills. <br/><br/> Additionally, since you will be working at a company relevant to your desired industry, our program also enables you to learn how your dream industry works.</p>
                                    <h2 className="section-heading" style={{fontSize: '32px', color: '#f288b1'}}><strong>Reason #3 DISCOVER Australian workplace culture and how to build a successful career overseas</strong><br/></h2>
                                    <p>The more you discover, the more you know!<br/> <br/> Throughout your internship, you will discover what the Australian workplace culture is like. This includes what their workplace etiquette is, whether there are workplace hierarchies and whether Australian employees are as laid-back as people say. Once you’ve experienced their workplace culture first-hand, you can decide whether starting a career in Australia is for you.<br/><br/>While completing your internship, you will also witness what other working professionals do to excel at their jobs. You can then adopt their practices to build a successful career in Australia or other foreign countries. </p>
                                    <p><strong>Bonus: Receive free Intercultural and Professional Development programs! </strong> </p>
                                    <p>To give you a more well-rounded internship experience, we offer free Intercultural and Professional Development programs.<br/><br/>Our Professional Development program provides resume-writing and interview coaching services that can boost your confidence and employability tremendously.<br/> <br/>By connecting you with Australian high school or university students, our Intercultural program enables you to expand your global social network in Australia (not just professional) and learn about the Australian education culture.<br/><br/>We also support candidates throughout their internships. For example, we help them learn how to use digital programs for work and we host social meetups that make them feel less alone during the pandemic. <br/><br/>If you still want to know more about what we have to offer, feel free to contact us by email at <span> <a style={{color: '#37c8cf'}} href= "mailto:eziexperience@ezitech.co">eziexperience@ezitech.co</a> </span>! We’re here to help and we certainly do not want the pandemic to stop you from getting real work experience. 
                </p>
                                    <p style={{marginTop: '32px'}}><span> <a style={{color: '#37c8cf'}} href="/signUp">Sign up</a> </span> today to experience local culture and practise English with native speakers.  </p>
                                </div>
                            </div>
                        </div>
                    </article>
            </div>
        )
    }
}

export default PostSix;