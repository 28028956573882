import React, { Component } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './host.css';

class HostTerms extends Component{
    render(){
        return(
            <div>
                <header className="host-terms">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="site-heading">
                                    <h1 style={{fontSize: '64px', textAlign: 'left', color: 'white'}}>EziExperience</h1>
                                    <h1 style={{fontSize: '64px', textAlign: 'left', color: 'white'}}>Terms &amp; Conditions for E-Hosts</h1>
                                    <h2 className="subheading" style={{textAlign: 'left', color: 'white'}}>code of conduct &amp; terms</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <article id="txtpg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-lg-8 mx-auto">
                            <p style={{marginBottom: '0px'}}><strong>Code of conduct for E-Hosts</strong></p>
                            <p>EziExperience reserve the right to may lead in your removal from our EziExperience community. In case, any breach of these requirements will result in a performance breach and the following requirements are minimum standards that hosts
                                must fulfil to keep a EziExperience profile.&nbsp;</p>
                            <p style={{marginBottom: '24px'}}><strong>1. E-Hosts are required to respect the following professional standards:</strong></p>
                            <ul>
                                <li>Communicating with students and EziExperience in a polite and respectful manner.</li>
                                <li>Respecting the commitment that is a booking. Unforeseen situations can of course happen, but being late, cancelling or not showing up to a booking without informing your students and without a valid reason is not acceptable.</li>
                                <li>Being available and reachable by both the students and the office, in particular for any urgent or time sensitive issue, and around the time of a booking.</li>
                                <li>Having an appropriate and professional presentation and outfit, respecting hygiene standards.</li>
                                <li>Avoiding any inappropriate behaviours such as smoking during a session. </li>
                            </ul>
                            <p style={{marginBottom: '24px'}}><strong>2.&nbsp;E-Hosts represent the EziExperience community, and are therefore expected to respect the following limitations:</strong><br/></p>
                            <ul>
                                <li>Sessions must always be led by the host of the booking, hosts should never send another person to fill in for a booking.</li>
                                <li>Be mindful of your online presence and set your privacy settings accordingly. Keep in mind the e-homestay image when sharing your social profiles.</li>
                                <li>Remain impartial and keep a service mind and a calm temper when interacting with students.</li>
                                <li>e-hosts must refrain from expressing negative feelings about EziExperience, or complaining about the company or any of its parties in front of the students or on public platforms. All complaints and feedback should be addressed directly
                                    to the EziExperience team.</li>
                                <li>Use the EziExperience platform genuinely to promote your profile and offer experiences to students but refrain from using the EziExperience platform to conduct other business with students (for example by trying to bypass the online
                                    booking system) or other hosts. </li>
                            </ul>
                            <p style={{marginBottom: '24px'}}><strong>3.&nbsp;E-&nbsp;Hosts commit to maintaining high standards of safety and security when providing a service to students.&nbsp;The following standards apply:</strong><br/></p>
                            <ul>
                                <li>e-hosts should always avoid unsafe situations and assess tour activities for potential hazards or critical points.&nbsp;</li>
                                <li>Hosts must never be intoxicated on a tour/experience. Drinking alcohol is forbidden with under 18 years students. </li>
                                <li>Emotional or intimate involvement with any student – whether real or perceived, and as assessed by EziExperience in its sole discretion, will lead to the instant removal from our EziExperience community. e-hosts are in a position
                                    of trust and are always expected to act accordingly while on a session.</li>
                            </ul>
                            <p><strong>EziExperiencere serves the right to change any of these conditions at any time deemed necessary to reflect current market and economic conditions.&nbsp;&nbsp;</strong><br/></p>
                        </div>
                    </div>
                </div>
            </article>
                <hr/>

            </div>
        )
    }
}

export default HostTerms;