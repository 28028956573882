import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './contactUs.css';

function Step3(props){
    
    if (props.currentStep !== 3){
        return null;
    }

    return(
        <div style={{marginTop: '1rem'}}>
            <h5 className="sub-heading mb-4">Personal Details</h5>
            
            <Form>
                <Form.Group as={Col} controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control defaultValue={props.firstName} onChange={props.handleChange}/>
                </Form.Group>

                <Form.Group as={Col} controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control defaultValue={props.lastName} onChange={props.handleChange}/>
                </Form.Group>
               

                <Form.Group as={Col} controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control defaultValue={props.email} onChange={props.handleChange}/>
                </Form.Group>

                <Form.Group as={Col} controlId="mobile">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control defaultValue={props.mobile} onChange={props.handleChange}/>
                </Form.Group>

            </Form>
        </div>
    )
    
}

export default Step3;