import React, { Component } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './host.css';

// import image
import i1 from "../../assets/img/i1_how.png";
import i3 from "../../assets/img/i3_how.png";
import i4 from "../../assets/img/i4_how.png";
import i5 from "../../assets/img/i5_how.png";


class HowItWorks extends Component{
    render(){
        return(
            <div>
                <header className="howitworks-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="site-heading">
                                    <h1 style={{paddingBottom: '17px', fontSize: '50px', color:'wheat'}}>
                                        <strong>How it works for E-Hosts?</strong>
                                    </h1>
                                    <span className="subheading" style={{paddingBottom: '36px', paddingTop: '20px'}}>EziExperience is an
                                        online marketplace for hosts and tutors to educate young learners and earn extra.</span>
                                    <a data-aos="fade" href="https://www.eziexperience.com/en/expert_signup"><button
                                            className="btn btn-primary" type="button"
                                            style={{margin: '16px', marginTop: '8px', background: '#f8c828'}}>
                                                <strong>Join EziExperience For Free</strong></button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container" style={{marginBottom: '72px'}}>
                    <div className="row">
                        <div className="col-md-10 col-lg-8 mx-auto">
                            <div>
                                <h1 style={{padding: '6rem 0 3rem 0', fontSize: '54px', textAlign: 'center', fontWeight:'600'}}>Become an E-Host for
                                        Free</h1>
                                <p style={{textAlign: 'center'}}>EziExperience provides the opportunity for you to create an authentic
                                    online experience, earn money flexibly and make a difference in the lives of young people. </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start: 1 Row 4 Columns */}
                <div>
                    <h1 style={{textAlign: 'center', marginBottom: '54px', fontWeight: '600'}}>Why work with us?</h1>
                    <div className="container">
                        <div className="row" style={{paddingBottom: '52px'}}>
                            <div className="col-md-3 text-center"><img src={i1} alt=""/>
                                <h4 className="text-uppercase" style={{textAlign: 'center'}}><strong>Keep yourself busy</strong><br/>
                                </h4>
                                <p style={{textAlign: 'center'}}>We work hard to constantly bring new students onboard to make sure
                                    you can keep hosting.</p>
                            </div>
                            <div className="col-md-3 text-center"><img src={i3} alt=""/>
                                <h4 className="text-uppercase" style={{textAlign: 'center', marginBottom: '9px'}}><strong>Set your<br/>own
                                        rules</strong></h4>
                                <p style={{textAlign: 'center'}}>Host as much or as little as you want. Choose a time that fits your
                                    schedule and lifestyle.</p>
                            </div>
                            <div className="col-md-3 text-center"><img src={i4} alt=""/>
                                <h4 className="text-uppercase" style={{textAlign: 'center'}}><strong>Explore your
                                        creativity</strong><br/></h4>
                                <p style={{textAlign: 'center'}}>Integrate any appropriate activities, past experiences, your
                                    lifestyle and knowledge to create an authentic online learning experience.</p>
                            </div>
                            <div className="col-md-3 text-center"><img className="img-fluid" src={i5} alt=""/>
                                <h4 className="text-uppercase" style={{textAlign: 'center'}}><strong>Straightforward Earning</strong></h4>
                                <p style={{textAlign: 'center'}}>Set and change your rate at any time. Receive payment straight to
                                    your bank account without any complicated process.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End: 1 Row 4 Columns */}

                <section>
                    {/* Start Know More: Highlight White */}
                    <div className="highlight-blue" style={{background: 'black'}}>
                        <div className="container" style={{paddingTop: '6rem'}}>
                            {/* Start: Intro */}
                            <div className="intro">
                                <h1 className="text-center"style={{color:'white'}}>Curious to know more?</h1>
                            </div>
                            {/* End: Intro */}
                            {/* Start: Buttons */}
                            <div className="row justify-content-center" style={{paddingBottom: '3rem'}}>
                                <div className="col-lg-6 col-sm-12 col-xs-12" align="right" style={{padding:'2rem 2rem 1.5rem 0'}}>
                                    <a href="/hostfaq">
                                        <button className="btn btn-primary" type="button" style={{padding: '15px 32px'}}>
                                            FAQ for hosts
                                        </button>
                                    </a>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-xs-12" align="left" style={{padding:'2rem 0 1.5rem 2rem'}}>
                                    <a href="/hostterms">
                                        <button className="btn btn-primary" type="button" style={{padding: '15px 32px'}}>
                                            T&C for hosts
                                        </button>
                                    </a>
                                </div>
                            </div>
                            {/* End: Buttons */}
                        </div>
                    </div>
                    {/* End: Highlight White */}

                    <div className="highlight-blue" style={{background: 'white', paddingTop: '4rem'}}>
                        <div className="container">
                            {/* Start: Intro */}
                            <div className="intro">
                                <h1 className="text-center" >Join Now as E-host</h1>
                                <p className="text-center" style={{color:'grey'}}><strong>Share your expert online and earn an
                                        average of $50 per hour</strong></p>
                            </div>
                            {/* End: Intro */}
                            {/* Start: Buttons */}
                            <div className="row justify-content-center" style={{paddingBottom: '3rem'}}>
                            <a href="https://www.eziexperience.com/en/expert_signup"><button
                                        className="btn btn-primary" type="button">Sign Up Now</button></a>
                            </div>
                            <div className="buttons"></div>
                            {/* End: Buttons */}
                        </div>
                    </div>
                    {/* End: Highlight Blue */}
                </section>

            </div>
        )
    }
}

export default HowItWorks;