import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './signUp.css';

import host from "../../assets/img/host.png";
import learner from "../../assets/img/learners.png";

class SignUp extends Component{
    render(){
        return(
            <div>
                <header className="signup-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="site-heading">
                                    <h1 ><strong>Create your account</strong></h1>
                                    <h1>Tell us who you are ?<br/></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* Start: 1 Row 2 Columns */}
                <div>
                    <div className="container" style={{paddingTop:'6rem'}}>
                        <div className="row" >
                            <div className="col-md-6" style={{textAlign: 'center'}}>
                                <h1 style={{color: '#f8c828'}}>For Hosts</h1>
                                <img src={host} style={{marginBottom: '36px', textAlign: 'center'}} alt=""/>
                                <a href="https://www.eziexperience.com/en/expert_signup" style={{paddingTop: '12px', paddingBottom: '14px'}}> <br/>
                                <button className="btn btn-primary" type="button" style={{background:'#f8c828', borderStyle: 'none'}}>Sign Up as Host</button></a></div>
                            <div
                                className="col-md-6" style={{textAlign: 'center'}}>
                                <h1 style={{color: '#f288b1'}}>For Learners</h1>
                                <img src={learner} style={{marginBottom: '36px', textAlign: 'center'}} alt=""/><br/>
                                <a href="https://www.eziexperience.com/en/customer_signup" style={{paddingTop: '12px', paddingBottom: '14px'}}>
                                <button className="btn btn-primary" type="button" style={{background:'#f288b1', borderStyle: 'none'}}>Sign Up As Learner</button></a></div>
                    </div>
                </div>
                </div>
                {/* End: 1 Row 2 Columns */}
                <h3 style={{textAlign: 'center', marginTop: '36px'}}>
                    Already have an account with EziExperience?&nbsp;
                    <a href="https://www.eziexperience.com/en/login" style={{color: '#65c6ce'}}>
                    <em>Login Now</em></a><br/>
                </h3>
                <hr/>
                </div>
        )
    }
}

export default SignUp;