import React, { Component } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './host.css';

class HostFAQ extends Component{
    render(){
        return(
            <div>
                <header className="faq-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="site-heading">
                                    <h1 style={{fontSize: '69px', color:'white'}}><strong>FAQ for eHosts&nbsp;</strong></h1><span className="subheading" style={{marginBottom: '24px', fontSize: '33px'}}> Some common Q&A for better understanding of <br/> EziExperience.</span></div>
                            </div>
                        </div>
                    </div>
                </header>
                    <article id="txtpg">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-10 col-lg-8 mx-auto">
                                    <h2 className="section-heading" style={{paddingBottom: '34px', paddingTop:'6rem'}}><strong>Punctuality&nbsp;</strong></h2>
                                    <p><strong>Q: When am I considered late ?</strong></p>
                                    <p>A: EziExperience considers any second after one minute to be late. </p>
                                    <p>For example, if the session starts at 15:00. But you enter the session at 15:01:01 you will be marked as late. We strongly recommend that you enter the session at least 10-15 minutes before the session to check your network, video
                                        and audio. </p>
                                    <p><strong>Q:What happens if I am 10 minutes late to a session ?</strong></p>
                                    <p className="text-break" style={{marginBottom: '13px'}}>A: If you are late more than 4 minutes for 40 minutes session and 3 minutes for 30 minutes session, it will be automatically marked as “ No-show.”&nbsp;</p>
                                    <p className="text-break" style={{marginTop: '0px'}}>Host makes more than 4 lateness over 4 weeks. You will not be able to schedule future sessions with EziExperience next 4 weeks.</p>
                                    <p><strong>Q:What happened if I’m late but make up for the lost time ?</strong><br/></p>
                                    <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A:We don’t encourage this. However, it will be considered as “complete” so you will be able to receive your payment for that session. Sessions need to be provided for a full 30 minutes or 4 minutes to be considered “complete”.</p>
                                    <p
                                        className="text-break" style={{marginTop: '0px'}}>For instance, if you are late to the session, you will need to make up for the lost time. </p>
                                        <p><strong>Q:How late does an e-host have to be for it to be considered as “ No-show” for the e-host ?</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: There are two circumstances under which hosts are marked as a “No-show”.</p>
                                        <ul>
                                            <li>For the 40-minute session service, when the host is late for more than 4 minutes or doesn’t show up. For the 30 minute session service, when the host is late for more than 3 minutes or doesn’t show up. </li>
                                            <li>When the host enters the session, but the student has already left the classroom after spending some time waiting. </li>
                                        </ul>
                                        <p className="text-break" style={{marginTop: '0px'}}>It is up to the students whether or not they want to wait for their hosts. However, please note that we always inform our students that we are trying to contact our hosts and ask then to wait in the class. If you know that you
                                            will be late for your session before the session’s stating time and think that your student would not wait for you, then you should cancel the session. </p>
                                        <p><strong>Q: How long do I have to wait in my session if the student is late?</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: In order to receive your full payment for the session, hosts need to wait in the classroom for at least 25 mins for 40 mins and 17 mins for 30 mins session. </p>
                                        <p className="text-break" style={{marginTop: '0px'}}>If your student has not yet entered even after above time from the session start time, you can leave the classroom. However, in the situation that students do enter the classroom late, we highly recommend hosts to still carry out
                                            a full 40/30 mins less for the student. </p>
                                        <p><strong>Q: I will be late to my session due to an emergency.&nbsp;</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A:If you know that you will be late for your session before the session’s starting time and think that your student would not wait for you, it is recommended that you cancel the session asap otherwise you will be marked as a “
                                            No-Show”. </p>
                                        <p className="text-break" style={{marginTop: '0px'}}>If you are facing trouble accessing the zoom, you can enter the classroom through your mobile phone as a quick fix and then enter the session with your laptop. You can also cancel sessions using your phone. </p>
                                        <p><strong>Q: Is it ok for me to be late for my next session if I am having two or more continues sessions?</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: No, it is not acceptable for hosts to be late for their sessions even if they have already communicated this with their students right before (e.g. if you have continued session with the same student).&nbsp;&nbsp; </p>
                                        <p className="text-break"
                                            style={{marginTop: '0px'}}>Since all metrics regarding hosts attendance are automatically managed by our system, hosts need to enter every session right before their starting time. </p>
                                        <p><strong>Q: Can you please tell my students that I will be late for the sessions?</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: Our monitoring team already informs any student waiting for their e-hosts that they are trying to get in touch with the hosts. This is regardless of whether hosts inform us beforehand or not. </p>
                                        <p className="text-break" style={{marginTop: '0px'}}>However, if the student decides to not wait for their e-host, the e-host is automatically marked as a “No-Show”. In the situation that the e-host is able to join the session after being already late, they are required to carry
                                            out a full session. </p>
                                        <h2 className="section-heading" style={{paddingBottom: '34px'}}><strong>Cancellation &amp; No-show&nbsp;</strong></h2>
                                        <p><strong>Q: Do I have to pay a penalty fee for a No-show?</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: For every no-show count, EziExperience deduct $10 from your payment.&nbsp; </p>
                                        <p><strong>Q: What if a student does not show up to the session?&nbsp;</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A:If a student does not show up for the session and you remain in the classroom for at least 25 mins for 40 mins session / 17 mins for 30 mins session, EziExperiencewill pay 100% of your hourly wage for the entire time scheduled
                                            for such sessions.&nbsp; </p>
                                        <p><strong>Q: I was late and did the session, but it is marked as "E-host No-show"</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: If you were late for more than 4 minutes for 40-mins session / 3 mins for 30-minw session, it will automatically be marked as a 'No-Show.' </p>
                                        <p className="text-break" style={{marginTop: '0px'}}>However, if you were late but if the student waited for you and the session fully proceeded for more than 40- mins/30-mins, you should inform our EziExperience team. </p>
                                        <p><strong>Q: I have to cancel my session due to an emergency.</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: For an emergency (e.g., a car accident, emergency hospital, etc), please contact eziexperience, and provide us the details of your situation with proof of the emergency (e.g., medical certificate) as an attachment. We will internally
                                            discuss this matter with our team to consider removing the warning for a cancellation/no-Show. Failure to provide acceptable proof of the emergency will be disregarded. </p>
                                        <p className="text-break" style={{marginTop: '0px'}}>*Noted: Planned surgery, illness (i.e. flu, high fever, sore throat, etc), Family event (i.e. Sister's wedding), Personal schedule (i.e: school Exam), Power Outage are NOT considered as Emergency. </p>
                                        <p><strong>Q: What should I do if the student cancels the session?</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: If the student canceled/doesn't show up for the session but you still want to be paid the full compensation of that session, you should enter the classroom and wait for more than 25 mins or 17 mins. </p>
                                        <p className="text-break"
                                            style={{marginTop: '0px'}}>If you wait more than 25mins or 17 mins, and there is no other re-assigned student who entered your session, you can exit and still get full payment. </p>
                                        <p><strong>Q: What is considered as "E-host No-show"?</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: There are two circumstances when the e-host gets the "No-show" mark.</p>
                                        <ul>
                                            <li>When the e-host is late more than 4 mins for 40-mins session / 3 mins for 30-mins session or doesn't show up. </li>
                                            <li>When the e-host entered the session but the student gave up waiting and already pressed the 'Exit' button. </li>
                                        </ul>
                                        <p className="text-break" style={{marginTop: '0px'}}>It's up to the student whether or not they want to wait for the e-host, although the monitoring team always sends a message to the student: "Please wait for the e-host. We are contacting the e-host now." </p>
                                        <h2 className="section-heading"
                                            style={{paddingBottom: '34px'}}><strong>Feedback Report&nbsp;</strong></h2>
                                        <p><strong>Q:When do I have to submit the Session Feedback Report?</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: Session Feedback Reports need to be submitted within 24 hours after the scheduled session time. </p>
                                        <p><strong>Q: What is not allowed in a Feedback Report?</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: Copying and pasting the same content of feedback and tips that are irrelevant to your student’s session is not allowed. </p>
                                        <p><strong>Q: I want to fix and re-submit the Feedback Report.&nbsp;</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: Please contact EziExperienceand tell us the reason for why you want to re-submit your student’s Feedback Report. </p>
                                        <h2 className="section-heading" style={{paddingBottom: '34px'}}><strong>Technical Issues</strong><br/></h2>
                                        <p><strong>Q: The network was very unstable during the session.&nbsp;</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A:&nbsp;</p>
                                        <ul>
                                            <li>Session: If you and your student agreed not to continue the session due to your unstable network, please provide your session ID. We will review your network history and confirm whether the problem occurred on your end or your
                                                student's end. </li>
                                            <li>Feedback report: If you carry out a session for more than 20 minutes, you are required to submit a feedback report. Note: We can check the network history of each session to see how many minutes you were able to remain in the
                                                session. </li>
                                        </ul>
                                        <p><strong>Q: My Zoom is not working during the session.</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: We recommend you to use Google Chrome to access our website. If this still does not work and you have to enter a classroom, we recommend you to enter the classroom using the class’s Zoom ID with the Zoom application on your
                                            mobile phones. Once you enter the classroom, you can inform the student about your situation. </p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>If the problem still persists, please contact to EziExperienceteam urgently. Our monitoring team will help you fix the matter urgently. However, if it is not operation hours, please contact us about this issue through email. </p>
                                        <p><strong>Q: I am having trouble hearing the student during the session.&nbsp;</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: You should start with checking your network and microphone condition before the session. You should also try and do your sessions in a quiet environment. Apart from that, we also recommend you to not use wireless earphones.
                                            </p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>If you are having any other technical issues (e.g. Zoom does not work, the student's audio has a problem, etc.) Please contact to EziExperienceteam urgently. Our monitoring team will support you. </p>
                                        <p><strong>Q: Will any network issues in my session affect to me for that session?&nbsp;</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: Yes, our monitoring team reviews everyone's network. </p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>If we notice any serious network issues on your end, we will send you an email informing you so. Additionally, if your student leaves a comment about your network, we will review your network loss and latency history. </p>
                                        <p><strong>Q:How will I get deducted for my unstable network connection?</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: When students file network issues (student’s network rating is 5 out of 7 or less) after their specific sessions, EziExperience’s Internet Connection Analysis will be performed based on statistical data provided by Zoom. </p>
                                        <p><strong>Q: Is there any way for me to improve my network?&nbsp;</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: We recommend the following tips to improve your network: </p>
                                        <ul>
                                            <li>Using secured private WiFi; using public WiFi can lead to a loss in video and audio. </li>
                                            <li>If you experience a weak internet connection even after trying all of the above, please try to turn off all the other programs running on your computer during the session time. You can also try to disconnect the WiFi connection
                                                from your smartphone as well. </li>
                                            <li>We highly recommend you to choose with local high speed network provider.&nbsp; </li>
                                        </ul>
                                        <h2 className="section-heading" style={{paddingBottom: '34px'}}><strong>Payment</strong><br/></h2>
                                        <p><strong>Q: When Do I get Paid ?</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: After the scheduled start date for a booking, EziExperience will release your payment every Thu after the start date into your account. You will receive a payment confirmation email once your payout has been released. </p>
                                        <p><strong>Q: My bonus (e.g. referrals, accepting sessions within a certain period, etc.) has not yet been issued.</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: If you have not yet received the bonus in over 2 weeks, please contact EziExperience team.&nbsp; </p>
                                        <p><strong>Q: The payment I received does not match the amount I should have received.&nbsp;</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: Before contacting us, please check the following: </p>
                                        <ul>
                                            <li>&nbsp;Did your level increase in the middle of the week? Your compensation is issued based on the rate you were receiving when you first accepted the session. </li>
                                            <li>Did you submit all of your Feedback Reports, or have any no-show/ network issues? This may have resulted in a deduction of your payment. </li>
                                            <li>Are you expecting a bonus to be issued to you? All bonuses will be issued as soon as possible, so please kindly wait. </li>
                                            <li>4. Did you consider the PayPal Fee? PayPal charges a personal fee whenever is money transferred into a person’s account. If you find that the difference is too large, kindly get in touch with us with the details (i.e. screenshots
                                                of how much money you should receive versus what you received). </li>
                                        </ul>
                                        <p><strong>Q: Do I require an ABN ?&nbsp;</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>Many competitors and national businesses adopt this business structure - just look at Uber, Deliveroo as well as some small time gigs such as baby-sitting, running a shopify store. </p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>EziExperience provides advice, materials &amp; support regarding best practices as a e-host however you should have everything you need to work as a host before commencement as an e-host operating with us. Our relationship consists
                                            of a network of hosts, and we help to match you with students whilst providing ongoing support and management of the relationship. </p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>From an ATO standpoint, you are an independent business (trading as a sole-trader) that we contract opportunities to. EziExperience is not an employer.&nbsp; </p>
                                        <p><strong>Q: Do I need to pay tax ?</strong><br/></p>
                                        <p className="text-break" style={{marginTop: '0px', marginBottom: '15px'}}>A: Declaring your income and paying tax are your personal responsibility. Please consult with your tax adviser. </p>
                                </div>
                            </div>
                        </div>
                    </article>


                
            </div>
        )
    }
}

export default HostFAQ;