import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './blog.css';

class PostSeven extends Component{
    render(){
        return(
            <div>
                 <header className="post_7">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="post-heading">
                                    <h1><strong>Five Tips For Working From Home Blog</strong><br/></h1><span className="meta">Posted by&nbsp;<a href="/">EziExperience</a>&nbsp;on 04/04/2021</span></div>
                            </div>
                        </div>
                    </div>
                </header>
                <article id="txtpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <p style={{margin: '0 0 20px 0'}}>Due to the coronavirus pandemic, work has transitioned from the office to our homes.
                                For interns or employees who now need to work from home, this transition can be scary and hard to adapt to.
                                Getting dressed, listening to K-pop on your way to work, chit chatting with co-workers, all these routine things are no longer routine when you work remotely.
                                So how do you stay productive at home? How do you separate work life from home life? How do you prevent yourself from succumbing to the stressful effects of a global pandemic?</p>

                                <p style={{margin: '0 0 20px 0'}}>Well, no matter how much remote work upsets or scares you, there are still ways to enjoy and excel at it. Here are five tips for working from home.</p>

                                <h2 className="section-heading" style={{fontSize: '32px', color: '#f8c828'}}><strong>Set a work schedule</strong><br/></h2>
                                <quot>Don’t let work consume you! </quot>
                                <p>When you work at home all day for a prolonged period of time, it is easy to let your work life bleed into your home life.
                                When this happens, you risk burning yourself out and lowering your productivity levels.
                                This is why it is important to stick to a work schedule structuring when you work, when you take breaks and when you finish work.
                                For example, you might begin work at 8am, have lunch from 12pm to 1pm, and finish work at 5pm.
                                Keep your work life as structured as possible so you can still recharge and enjoy your hobbies after work. </p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#65c6ce'}}><strong>Get out of your pyjamas </strong><br/></h2>
                                <quot>Get dressed for work! </quot>
                                <p>It’s tempting to stay in your pyjamas for remote work. No one is going to see you anyway, so why bother getting dressed?
                                As someone who has experimented with working in pyjamas and working in office attire,
                                I can assure you that you will be a lot more productive working in work appropriate attire. When you put on a proper shirt,
                                you are telling yourself that it is time to get down to business. One of the biggest perks of getting dressed is that you no longer have
                                to fight the urge to go back to bed! </p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#f288b1'}}><strong>Communicate your thoughts clearly and straightforwardly</strong><br/></h2>
                                <q>Reflect your emotions in your texts! </q>
                                <p>Working remotely means talking to your co-workers through texts or other online platforms. As you may already know from texting your friends,
                                it is hard to detect what the other person is feeling from reading their texts. Similarly, your boss might not be able to detect your tone when
                                you reply “Okay” to their requests. Are you being rude? Are you being dismissive? To prevent misunderstandings, use emojis in your messages.
                                Put a smiley face after “Okay” to let them know you are truly “Okay” with whatever they ask of you.  </p>
                                <p><strong>Bonus: Receive free Intercultural and Professional Development programs! </strong> </p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#65c6ce'}}><strong>Take care of yourself </strong><br/></h2>
                                <quot>Let yourself relax and take full breaks!  </quot>
                                <p>Since you will be communicating remotely, it is easy for your co-workers to contact you even during your breaks.
                                Whenever that happens, resist the urge to reply unless they send you urgent messages.
                                Log off your work communication channels when you’re resting. Relax when you can so that you won’t build up stress and frustration later on. </p>

                                <h2 className="section-heading" style={{fontSize: '32px', color: '#f8c828'}}><strong>Set boundaries with people who live with you</strong><br/></h2>
                                <quot>Let people who live with you know when they can hang out with you and when they can’t! </quot>
                                <p>The activities your family or housemates do can be distracting. You definitely don’t want them barging into your room while you’re in the
                                    middle of a zoom call with your boss (trust me, it happens). To stay focused on your work and avoid embarrassing situations, set some boundaries with them.
                                    Let them know when you need them to be quiet and when they’re welcome to play with you. Communication is key. </p>



                                <p>These are the five tips for working from home. Hopefully you feel more at ease with remote working after reading this article.
                                If you’re currently looking for remote work, check out Eziexperience’s Australian Online Internship programs! </p>
                                <p style={{marginTop: '32px'}}><span> <a style={{color: '#37c8cf'}} href="/signUp">Sign up</a> </span> today to experience local culture and practise English with native speakers.  </p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default PostSeven;