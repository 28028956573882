import React, {Component} from 'react';

import Alert from 'react-bootstrap/Alert';
import emailjs from 'emailjs-com';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './contactUs.css';

import MultiStepProgressBar from "./MultiStepProgressBar";
import Step1 from "./Step1.js";
import Step2 from "./step2.js";
import Step3 from "./step3.js";
import Step4 from "./step4.js";
import Step5 from "./step5.js";

class ContactUs extends Component{
    constructor(props) {
        super(props);
    
        // Set the intiial input values
        this.state = {
            service1: ["homestay"],
            service2: ["eHost"],
            currentStep: 1,
            email: '',
            lastName: '',
            firstName: '',
            company:'',
            mobile: '',
            city: '',
            state: '',
            zipCode: '',
            message: '',
            alertForm1: false,
            alertForm2: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleServiceChange = this.handleServiceChange.bind(this);
        this._next = this._next.bind(this);
        this._prev = this._prev.bind(this);
        this.submit = this.submit.bind(this);
    }

    handleChange(event){
        const { id, value } = event.target;
        this.setState({
          [id]: value
        });
    }

    handleServiceChange(service, value){
        var result = this.state.service1;
        if(service !== "service1"){
            result = this.state.service2;
        }
        if(this.state.service1.includes(value)){
            this.setState({
                [service]: result.filter(e => e !== value)
            })
        }else{
            this.setState({
                [service]: result.concat(value)
            })
        }
    }

    // Test current step with ternary
    // _next and _previous functions will be called on button click
    _next() {
        let currentStep = this.state.currentStep;
        if(currentStep === 3){
            if(this.state.email === "" || this.state.firstName === "" || this.state.lastName === "" || this.state.mobile === ""){
                this.setState({
                    alertForm1: true
                })
            }else{
                currentStep = currentStep >= 4 ? 5 : currentStep + 1;
                this.setState({
                    currentStep: currentStep,
                    alertForm1: false
                });
            }
        }else{
            // If the current step is 1 or 2 or 3 or 4, then add one on "next" button click
            currentStep = currentStep >= 4 ? 5 : currentStep + 1;
            this.setState({
                currentStep: currentStep
            });
         }
    }


    _prev() {
        let currentStep = this.state.currentStep;
        // If the current step is 2 or 3 or 4, then subtract one on "previous" button click
        currentStep = currentStep <= 1 ? 1 : currentStep - 1;
        this.setState({
        currentStep: currentStep
        });
    }

    submit(e){
     
        if(this.state.company === "" || this.state.zipCode === "" || this.state.state === "" || this.state.city === ""){
            this.setState({
                alertForm2: true
            })
        }else{
            this.setState({
                currentStep: 5,
                alertForm2: false
            });

            e.preventDefault();

            var userInfor = {
                subject_name: "Contacter Details",
                Service_Required: "Service required: " + this.state.service1.concat(this.state.service2),
                first_name: this.state.firstName,
                last_name: this.state.lastName,
                email: this.state.email,
                address: "User doesn't provide this information",
                website: "User doesn't provide this information",
                company: this.state.company,
                mobile: this.state.mobile,
                city: this.state.city,
                state: this.state.state,
                zip_code: this.state.zipCode,
                type_of_business: "User doesn't provide this information",
                monthly_visits: "User doesn't provide this information",
                message: this.state.message === ''? "User doesn't provide this information" : this.state.message,
            }

            console.log(userInfor);

            emailjs.send('gmail', 'template_6ok1gew', userInfor, 'user_zfg6ZlcWtvwNJabsQj93Z')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        }
    }

    // The "next" and "previous" button functions
    get previousButton() {
        let currentStep = this.state.currentStep;

        // If the current step is not 1, then render the "previous" button
        if (currentStep !== 1 & currentStep<5) {
        return (
            <button className="btn-block btn-secondary mt-3 mb-1 prev" onClick={this._prev}>PREVIOUS</button>
        );
        }

        // ...else return nothing
        return null;
    }

    get nextButton() {
        let currentStep = this.state.currentStep;
        // If the current step is not 4, then render the "next" button
        if (currentStep < 4) {
        return (
            <button id="next1" className="btn-block btn-primary mt-3 mb-1 next" onClick={this._next}>NEXT</button>
        );
        }
        // ...else render nothing
        return null;
    }

    get submitButton() {
        let currentStep = this.state.currentStep;

        // If the current step is the last step, then render the "submit" button
        if (currentStep === 4) {
        return(
            <button id="next3" className="btn-block btn-primary mt-3 mb-1 next mt-4" onClick={this.submit}>SUBMIT REQUEST</button>
        ) 
        }
        // ...else render nothing
        return null;
    }

    render(){
        return(
            <div>
                {/*    header-area-end*/}
                <header className="contact-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="site-heading">
                                    <h1 style={{color:'wheat'}}>Contact Us</h1>
                                    <span className="subheading" style={{fontSize: '34px'}}>The ultimate virtual experience of living abroad<br/></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-fluid px-1 py-5 mx-auto">
                <div className="row d-flex justify-content-center">
                    <div className="col-xl-5 col-lg-6 col-md-7">
                        <div className="card b-0">
                            <MultiStepProgressBar currentStep={this.state.currentStep}/>
                            <Step1
                                currentStep={this.state.currentStep}
                                handleServiceChange={this.handleServiceChange}
                                service1={this.state.service1}
                            />
                            <Step2
                                currentStep={this.state.currentStep}
                                handleServiceChange={this.handleServiceChange}
                                service2={this.state.service2}
                            />
                            <Step3
                                currentStep={this.state.currentStep}
                                handleChange={this.handleChange}
                                firstName={this.state.firstName}
                                lastName={this.state.lastName}
                                mobile={this.state.mobile}
                                email={this.state.email}
                            />

                            <Step4
                                currentStep={this.state.currentStep}
                                handleChange={this.handleChange}
                                company={this.state.company}
                                zipCode={this.state.zipCode}
                                state={this.state.state}
                                city={this.state.city}
                                message={this.state.message}
                            />

                            <Step5
                                currentStep={this.state.currentStep}
                            />

                            {this.previousButton}
                            {this.nextButton}
                            {this.submitButton}

                            {this.state.alertForm1 ? (
                                <Alert variant="danger">Please fill in all personal information</Alert>
                            ):(
                                null
                            )}
                            
                            {this.state.alertForm2 ? (
                                <Alert variant="danger">Please fill in all company information</Alert>
                            ):(
                                null
                            )}
                        </div>
                    </div>
                </div>
                </div>
                <hr/>

            </div>
        )
    }
}

export default ContactUs;