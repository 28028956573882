import React, { Component } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './host.css';

class FAQinternship extends Component{
    render(){
        return(
            <div>
                 <header className="faq-header" style={{height:'80vh'}} >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="site-heading">
                                    <h1 style={{fontSize: '69px', color:'white', paddingTop:'20vh'}}><strong>FAQ for Online Internship&nbsp;</strong></h1>
                                    <span className="subheading" style={{marginBottom: '24px', fontSize: '33px', color:'white'}}> Some common Q&A for better understanding of <br/> EziExperience's Online Internships.</span></div>
                            </div>
                        </div>
                    </div>
                </header>

                <article id="txtpg">
                    <div className="container" style={{paddingTop:'6rem'}}>
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <p><strong>Q: What is an online internship?</strong></p>
                                <p>A: Similar to offline internships, you will gain valuable work experience from online internships. You will still have supervision, networking opportunities and practical tasks to do.</p>
                                <p>The only difference is that you can improve your technical skills in the process and communicate with your supervisor,
                                    mentors or fellow workers from anywhere, be it your home or coffee shops. </p>

                                <p><strong>Q: Why should I choose EziExperience’s Online Internship program over others?</strong></p>
                                <p className="text-break" style={{marginBottom: '13px'}}>A: We provide a well-rounded program that ensures you will gain practical work experience and obtain employment in the long run.&nbsp;</p>
                                <p className="text-break" style={{marginTop: '0px'}}>We do this by offering free virtual Intercultural and Professional Development programs that,
                                    respectively, allow you to network with local students/working professionals and enhance your employability.</p>
                                <p>Additionally, we have bilingual staff who can support you through communication difficulties. </p>
                                <p><strong>Q: What kind of companies can I get a placement in?</strong><br/></p>
                                <p className="text-break"  style={{marginTop: '0px', marginBottom: '15px'}}>A: We place candidates in companies of all sizes and sectors. Sectors include marketing, communication, accounting, business, I.T., design, legal and tourism.</p>
                                <p
                                    className="text-break" style={{marginTop: '0px'}}>We place candidates in companies that match their interests or career goals, so candidates can always consult with us if they have specific preferences.
                                </p>

                                    <p><strong>Q: Are placements guaranteed? </strong><br/></p>
                                    <p className="text-break"  style={{marginTop: '0px', marginBottom: '15px'}}>A: We guarantee that you will be matched with a hosting company that suits your needs. Do not worry about getting rejected by companies! We have got your back.
                                    </p>
                                    
                                    <p><strong>Q: Do you offer paid internships?</strong><br/></p>
                                    <p className="text-break"  style={{marginTop: '0px', marginBottom: '15px'}}>A: We do not currently offer paid internships. However, it is negotiable with your host company. </p>
                                    <p className="text-break" style={{marginTop: '0px'}}>We also think that it is more important for you to gain work experience relevant to your career direction, so we prioritise matching you with companies that offer valuable projects rather than limiting you to paid internships.</p>

                                <p><strong>Q: Are there any English language requirements for candidates?&nbsp;</strong><br/></p>
                                    <p className="text-break"  style={{marginTop: '0px', marginBottom: '15px'}}>A: Yes, we do have an English language requirement for candidates. You will need an IELTS score of above 4.5 or equivalent to participate. </p>

                                <p><strong>Q: Is it ok for me to be late for my next session if I am having two or more continues sessions?</strong><br/></p>
                                    <p className="text-break"  style={{marginTop: '0px', marginBottom: '15px'}}>A: No, it is not acceptable for hosts to be late for their sessions even if they have already communicated this with their students right before (e.g. if you have continued session with the same student).&nbsp;&nbsp; </p>
                                    <p className="text-break"
                                        style={{marginTop: '0px'}}>Since all metrics regarding hosts attendance are automatically managed by our system, hosts need to enter every session right before their starting time. </p>

                                    <p><strong>Q: What is the duration of the internship?</strong><br/></p>
                                    <p className="text-break"  style={{marginTop: '0px', marginBottom: '15px'}}>A:We offer two types of online internship programs. Our 12-week internship is 12 weeks long, whereas our Summer & Winter Internship has a customisable duration (it is generally 5 weeks long). </p>

                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default FAQinternship;