import React, { Component } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './inviteFriend.css';

import ReferIco from "../../assets/img/ReferIco.png";
import DiscountIco from "../../assets/img/DiscountIco.png";
import MoneyIco from "../../assets/img/moneyIco.png";

class InviteFriend extends Component{
    render(){
        return(
            <div>
                <header className="invite-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="site-heading">
                                    <h1 style={{fontSize: '52px', color: 'white', paddingTop:'15vh'}}>Give a discount to your friends&nbsp;and pay less<br/></h1>
                                    <span className="subheading">
                                        <input readOnly type="url" id="refurl" style={{paddingTop: '6px', marginTop: '0px', borderRadius: '26px', fontSize: '16px', paddingBottom: '6px', paddingRight: '12px', width: '300px'}} placeholder="https://www.eziexperience.com/en/customer_signup" value="https://www.eziexperience.com/en/customer_signup" />
                                        <button className="btn btn-primary" type="button" style={{borderRadius: '55px', paddingRight: '42px', paddingLeft: '42px', paddingBottom: '6px', paddingTop: '6px', marginLeft: '16px', background: '#f288b1', borderColor: '#f288b1'}} onClick={() =>  navigator.clipboard.writeText('https://www.eziexperience.com/en/customer_signup')}>
                                            Copy
                                        </button><br/>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* Start: 2 Rows 1+3 Columns */}
                <div>
                    <div className="container" style={{paddingTop:'6rem'}}>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 style={{textAlign: 'center', color: '#65c6ce', marginBottom: '46px'}}><strong>How it works</strong></h1>
                            </div>
                        </div>
                        <div className="row" style={{marginBottom: '64px'}}>
                            <div className="col-md-4" style={{textAlign: 'center'}}><img className="img-fluid" src={ReferIco} style={{padding: '0px', width: '200px', marginBottom: '42px'}} alt="" />
                                <h1 style={{fontSize: '28px'}}><strong>You give a discount <br/> to your friends</strong></h1>
                                <p style={{textAlign: 'center', fontSize: '16px'}}>You can invite friends with your referral link.<br/>They get $10 discount for the first payment</p>
                            </div>
                            <div className="col-md-4" style={{textAlign: 'center'}}><img className="img-fluid" src={DiscountIco} style={{padding: '0px', width: '200px', marginBottom: '42px'}} alt="" />

                                <h1 style={{fontSize: '28px'}}><strong>Your friends <br/>use discount</strong></h1>
                                <p style={{textAlign: 'center', fontSize: '16px'}}>Your friends choose a tutor<br/>and pay less with your discount</p>
                            </div>
                            <div className="col-md-4" style={{textAlign: 'center'}}><img src={MoneyIco} style={{padding: '0px', width: '200px', marginBottom: '42px'}} alt="" />
                                <h1 style={{fontSize: '28px'}}><strong>You get money</strong><br/><strong>&nbsp;</strong><br/></h1>
                                <p style={{textAlign: 'center', fontSize: '16px'}}>As soon as your friend used a discount<br/>you'll get $10 of their deal to your account</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End: 2 Rows 1+3 Columns */}



                {/* Start: Highlight Phone */}
                <div className="highlight-phone">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                {/* Start: Intro */}
                                <div className="intro" style={{paddingBottom: '5rem'}}>
                                    <h1 style={{color:'#f8c828', fontSize: '48px', paddingTop: '12px'}}>Refer a friend&nbsp;</h1>
                                    <p style={{textAlign:'left', color: '#65c6ce', marginBottom: '8px', fontSize: '32px'}}>You'll receive AUD $50 when your friend make their first AUD $50 with EziExperience.&nbsp;<br/></p>
                                    <p style={{fontFamily: '\'Open Sans\', sans-serif', color: 'rgb(255,255,255)', marginTop: '32px', fontSize: '15px', marginBottom: '32px'}}>*Terms and conditions apply.<br/></p>
                                    <a className="btn btn-primary" role="button" href="/signUp" style={{background: '#f288b1', padding: '15px 32px'}}>Refer to your friend</a></div>
                                {/* End: Intro */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* End: Highlight Phone */}
            </div>
        )
    }
}

export  default InviteFriend;