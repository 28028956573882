import React, {Component} from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './blog.css';

class Blog extends Component{
    render(){
        return(
            <div>    
                <header className="blog_home">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="site-heading">
                                    <h1>EziExperience Blog&nbsp;</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                 <div className="container" style={{paddingTop:"6rem"}}>
                    <Row xl={3} lg={2} xs={1}>
                        <Col style={{marginTop: '1rem'}}>
                            <Card className='left' style={{ width: '25rem' , height:'30rem'}} onClick={()=>window.location.href = '/blog/post_1' }>
                            <Card.Body>
                                <Card.Title style={{textAlign: 'left',fontSize: '25px'}}>
                                    <strong>EziExperience sessions with Meiho High School</strong>    
                                </Card.Title>
                                <Card.Text style={{textAlign: 'left', fontSize: '19px'}}>
                                    Exciting experience with Meiho students
                                </Card.Text>
                                <footer className="blockquote-footer">
                                    Posted by EziExperience on 18/08/2020
                                </footer>
                            </Card.Body>
                            <button style={{bottom: 0, border: 0}}>Read more ...</button>
                            </Card>
                        </Col>

                        <Col style={{marginTop: '1rem'}}>
                            <Card className='left' style={{ width: '25rem' , height:'30rem'}} onClick={()=>window.location.href = '/blog/post_3' }>
                            <Card.Body>
                                <Card.Title style={{textAlign: 'left',fontSize: '25px'}}>
                                    <strong>3 tips to overcome the challenges while studying abroad.</strong>    
                                </Card.Title>
                                <Card.Text style={{textAlign: 'left', fontSize: '19px'}}>
                                    Help in fostering a positive environment for your child at home during these trying times. 
                                </Card.Text>
                                <footer className="blockquote-footer">
                                    Posted by EziExperience on 20/07/2020
                                </footer>
                            </Card.Body>
                            <button style={{bottom: 0, border: 0}}>Read more ...</button>
                            </Card>
                        </Col>

                        <Col style={{marginTop: '1rem'}}>
                            <Card className='left' style={{ width: '25rem' , height:'30rem'}} onClick={()=>window.location.href = '/blog/post_2' }>
                            <Card.Body>
                                <Card.Title style={{textAlign: 'left',fontSize: '25px'}}>
                                    <strong>How to support learning during a pandemic.</strong>    
                                </Card.Title>
                                <Card.Text style={{textAlign: 'left', fontSize: '19px'}}>
                                    We predict too much for the next year and yet far too little for the next ten.
                                </Card.Text>
                                <footer className="blockquote-footer">
                                    Posted by EziExperience on 10/05/2020
                                </footer>
                            </Card.Body>
                            <button style={{bottom: 0, border: 0}}>Read more ...</button>
                            </Card>
                        </Col>
                    </Row>

                    <Row xl={3} lg={2} xs={1}>
                        <Col style={{marginTop: '1rem'}}>
                            <Card className='left' style={{ width: '25rem' , height:'30rem'}} onClick={()=>window.location.href = '/blog/post_4' }>
                            <Card.Body>
                                <Card.Title style={{textAlign: 'left',fontSize: '25px'}}>
                                    <strong>How to experience culture and practise English with your children from home</strong>    
                                </Card.Title>
                                <Card.Text style={{textAlign: 'left', fontSize: '19px'}}>
                                    Many say exploration is part of our destiny, but it’s actually our duty to future generations.
                                </Card.Text>
                                <footer className="blockquote-footer">
                                    Posted by EziExperience on 20/04/2020
                                </footer>
                            </Card.Body>
                            <button style={{bottom: 0, border: 0}}>Read more ...</button>
                            </Card>
                        </Col>

                        <Col style={{marginTop: '1rem'}}>
                            <Card className='left' style={{ width: '25rem' , height:'30rem'}} onClick={()=>window.location.href = '/blog/post_5' }>
                            <Card.Body>
                                <Card.Title style={{textAlign: 'left',fontSize: '25px'}}>
                                    <strong>Are Virtual Internship Programs Worth It?</strong>    
                                </Card.Title>
                                <Card.Text style={{textAlign: 'left', fontSize: '19px'}}>
                                    Here are 5 benefits of doing an online overseas internship
                                </Card.Text>
                                <footer className="blockquote-footer">
                                    Posted by EziExperience on 24/03/2021
                                </footer>
                            </Card.Body>
                            <button style={{bottom: 0, border: 0}}>Read more ...</button>
                            </Card>
                        </Col>

                        <Col style={{marginTop: '1rem'}}>
                            <Card className='left' style={{ width: '25rem' , height:'30rem'}} onClick={()=>window.location.href = '/blog/post_6' }>
                            <Card.Body>
                                <Card.Title style={{textAlign: 'left',fontSize: '25px'}}>
                                    <strong>3 Reasons Why You Should Join Eziexperience’s Online Internship Program.</strong>    
                                </Card.Title>
                                <Card.Text style={{textAlign: 'left', fontSize: '17px'}}>
                                    Are you an international university student, graduate or job seeker who wants to do an internship in Australia? 
                                </Card.Text>
                                <footer className="blockquote-footer">
                                    Posted by EziExperience on 03/03/2021
                                </footer>
                            </Card.Body>
                            <button style={{bottom: 0, border: 0}}>Read more ...</button>
                            </Card>
                        </Col>
                    </Row>

                    <Row xl={3} lg={2} xs={1} style={{marginBottom: '3rem'}}>
                        <Col style={{marginTop: '1rem'}}>
                            <Card className='left' style={{ width: '25rem' , height:'30rem'}} onClick={()=>window.location.href = '/blog/post_7' }>
                            <Card.Body>
                                <Card.Title style={{textAlign: 'left',fontSize: '25px'}}>
                                    <strong>Five Tips For Working From Home Blog</strong>    
                                </Card.Title>
                                <Card.Text style={{textAlign: 'left', fontSize: '19px'}}>
                                    Well, no matter how much remote work upsets or scares you, there are still ways to enjoy and excel at it.
                                    Here are five tips for working from home 
                                </Card.Text>
                                <footer className="blockquote-footer">
                                    Posted by EziExperience on 04/04/2021
                                </footer>
                            </Card.Body>
                            <button style={{bottom: 0, border: 0}}>Read more ...</button>
                            </Card>
                        </Col>

                        <Col style={{marginTop: '1rem'}}>
                            <Card className='left' style={{ width: '25rem' , height:'30rem'}} onClick={()=>window.location.href = '/blog/post_8' }>
                            <Card.Body>
                                <Card.Title style={{textAlign: 'left',fontSize: '25px'}}>
                                    <strong>Tips for Job Interview</strong>    
                                </Card.Title>
                                <Card.Text style={{textAlign: 'left', fontSize: '19px'}}>
                                    Almost everyone can submit an impressive resume for an internship, so how do you stand out from the crowd?
                                </Card.Text>
                                <footer className="blockquote-footer">
                                    Posted by EziExperience on 14/04/2021
                                </footer>
                            </Card.Body>
                            <button style={{bottom: 0, border: 0}}>Read more ...</button>
                            </Card>
                        </Col>

                        <Col style={{marginTop: '1rem'}}>
                            <Card className='left' style={{ width: '25rem' , height:'30rem'}} onClick={()=>window.location.href = '/blog/post_9' }>
                            <Card.Body>
                                <Card.Title style={{textAlign: 'left',fontSize: '25px'}}>
                                    <strong>Sample Interview Questions</strong>    
                                </Card.Title>
                                <Card.Text style={{textAlign: 'left', fontSize: '19px'}}>
                                    Although you cannot predict what questions will be asked during the interview, you still need to practise answering sample interview questions
                                </Card.Text>
                                <footer className="blockquote-footer">
                                    Posted by EziExperience on 23/04/2021
                                </footer>
                            </Card.Body>
                            <button style={{bottom: 0, border: 0}}>Read more ...</button>
                            </Card>
                        </Col>
                    </Row>

                    <Row xl={3} lg={2} xs={1} style={{marginBottom: '3rem'}}>
                        <Col style={{marginTop: '1rem'}}>
                            <Card className='left' style={{ width: '25rem' , height:'30rem'}} onClick={()=>window.location.href = '/blog/post_10' }>
                            <Card.Body>
                                <Card.Title style={{textAlign: 'left',fontSize: '25px'}}>
                                    <strong>Has Covid19 brought changes to your business?</strong>    
                                </Card.Title>
                                <Card.Text style={{textAlign: 'left', fontSize: '19px'}}>
                                    Here is an EziExperience story!
                                </Card.Text>
                                <footer className="blockquote-footer">
                                    Posted by EziExperience on 02/06/2021
                                </footer>
                            </Card.Body>
                            <button style={{bottom: 0, border: 0}}>Read more ...</button>
                            </Card>
                        </Col>

                        <Col style={{marginTop: '1rem'}}>
                        </Col>

                        <Col style={{marginTop: '1rem'}}>
                        </Col>
                    </Row>

                </div>
            </div>
        )
    }
}

export default Blog;