import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './blog.css';


class PostFour extends Component{
    render(){
        return(

            <div>
                <header className="post_4" style={{ backgroundSize: 'cover'}}>
                    <div className="container">
                        <div className="row" style={{height:"80vh"}}>
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="post-heading" style={{position: 'absolute', top:'30%'}}>
                                    <h1 style={{color: 'white'}}>
                                        <strong>How to experience culture and practise English with your children from home</strong>
                                        <br/>
                                        </h1><span className="meta">Posted by&nbsp;<a href="/">EziExperience</a>&nbsp;on 20/04/2020</span></div>
                            </div>
                        </div>
                    </div>
                </header>

                <article id="txtpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <p>Experiencing a new culture and learning English can be beneficial, both for you and your children as it provides you the ability to master English, understand local culture, and build confidence. It is also a great way to spend more
                                    quality time and be engaged in an activity together. We have found a few ways for you to explore culture and practise English together with your children. </p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#f8c828'}}><strong>Create an English and Exploration corner</strong><br/></h2>
                                <p>Creating an area in the house for cultural exploration and practising English is a great way to spend time together through this experience. This space can act as a mini-library with books, board games,displays of English culture (pictures,
                                    flags, and drawings), among many others. Getting English books with cultural content can also be useful such as history content and fictional story books. Apart from that, you and your children can also practise English by reading
                                    aloud together, exploring themes,cultural context, and new words (keeping translations nearby might also be handy). It is definitely a whole new world at the English and Exploration corner. </p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#65c6ce'}}><strong>Watch TV shows or listen to music together</strong><br/></h2>
                                <p>Watching TV shows or listening to music together can help in this process. There are plenty of content out there, which span from lifestyle, food, culture, travel, among many others! For instance,some cooking shows may incorporate
                                    information about the country and their lifestyle. Besides that,you are able to learn skills such as conversational, listening and comprehension skills through these shows. You will also be able to understand cultural nuances.
                                    In fact, you might want to try the recipes you have watched with your children, it is a win-win. Also, do you know that there are TV programs that specifically cater to practising English? Just search for “good sitcoms to learn
                                    English”and you will be amazed at the variety of shows available. </p>
                                <h2 className="section-heading" style={{fontSize: '32px', color: '#f288b1'}}><strong>Converse and practise together</strong><br/></h2>
                                <p>What a better way to improve English while supporting one another than conversing and practising together. You may find topics that are relevant and interesting to your children’s lives. This helps in keeping them motivated and engaged
                                    to learn, perhaps something they enjoy talking about and can relate to. Each individuals may have different interests, as some teenagers might prefer music,politics, or their day at school. This is the perfect time to get to know
                                    one another better, and give them support they need through this. </p>
                                <p>eziexperience provides all the above for you through our virtual experience to explore culture and practise English. At eziexperience, we make sure learning is interactive and fun for you, so our e-hosts will bring you for excursions to
                                    learn English and explore the local lifestyle. Be prepared to visit the zoo, sightseeing by the beach, and even order a coffee in local cafes! Did we mention that the virtual experience is live? Yes, it is live, so you can ask
                                    questions on the spot and interact with our e-hosts.You can also make friends with participants from all over the world through eziexperience sessions. Check out (e-hosts name) as she brings us to (name of beach/ excursion). – put
                                    YouTube video link to illustrate one of the virtual excursions/ promo video. </p>
                                <p style={{marginTop: '32px'}}><span> <a style={{color: '#37c8cf'}} href="/signUp">Sign up</a> </span> today to experience local culture and practise English with native speakers.  </p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default PostFour;