import React, {Component} from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/responsive.css';
import './blog.css';

import blogImg_1 from '../../assets/img/blog8_0.jpeg';
import blogImg_2 from '../../assets/img/blog8_1.png';

class PostEight extends Component{
    render(){
        return(
            <div>
                <header className="post_8">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <div className="post-heading">
                                    <h1><strong>Tips for Job Interview </strong><br/></h1><span className="meta">Posted by&nbsp;<a href="/">EziExperience</a>&nbsp;on 14/04/2021</span></div>
                            </div>
                        </div>
                    </div>
                </header>
                <article id="txtpg">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 mx-auto">
                                <p style={{margin: '0 0 20px 0'}}>Almost everyone can submit an impressive resume for an internship, so how do you stand out from the crowd? Well, the key to getting hired is to ace your interview and impress your hiring manager.
                                If you are nervous about this, do not worry, we prepared some useful tips for you to succeed in any interview from any sector. </p>
                                <img src={blogImg_1} alt=""/>
                                <p>Familiarise yourself with the company. For example, check out their website, LinkedIn or other social media sites to see what their mission, services and customers are.
                                Do this so you can demonstrate that you are passionate about the company and that you are a great fit for them.</p>
                                <p>Double or triple read your resume until you can recite your strengths without hesitating. Relate those strengths to your job position during the interview.</p>
                                <p>Practise answering traditional and behavioural interview questions so that you can be more confident in the interview.
                                Traditional questions are general questions that enable the hiring manager to learn more about you and how you can contribute to the company,
                                whereas behavioural questions are situation-specific questions that allow the hiring manager to see whether your behaviour in certain situations is ideal for the company.
                                Check out the “Sample Questions” section to kick-start your interview preparation.</p>
                                <p>Practise answering traditional and behavioural interview questions so that you can be more confident in the interview.
                                Traditional questions are general questions that enable the hiring manager to learn more about you and how you can contribute to the company,
                                whereas behavioural questions are situation-specific questions that allow the hiring manager to see whether your behaviour in certain situations is ideal for the company.
                                Check out the “Sample Questions” section to kick-start your interview preparation.</p>
                                <p>To show that you are keen for the internship you are applying for, brainstorm some questions that you can ask on the day. We have listed some example questions in the “Sample Questions” section for inspiration. </p>
                                <q>Clean the space you are doing your online interview in to leave a good impression.</q>

                                <h2 className="section-heading" style={{fontSize: '32px', color: '#65c6ce'}}><strong>What should I do on the day of the interview?</strong><br/></h2>
                                <img src={blogImg_2} alt="" />
                                <ul>
                                <li>When doing virtual interviews, it is important to have a stable wi-fi connection. If the wi-fi at your home is often unstable, do the interview at a quiet place that has better wi-fi connection. </li>
                                <li>Be punctual to demonstrate that you are reliable and disciplined.</li>
                                <li>Wear something that suits your company’s brand image. This means no hoodies for an accounting job interview. </li>
                                <li>Turn on airplane mode to prevent disruptions from loud notifications. </li>
                                <li>Be polite and respectful to your interviewer. This means paying attention to your body language during the interview.</li>
                                <li>Let your personality shine while still maintaining a professional demeanour. Act like someone you would want to work with.</li>
                                <li>Always relate the interview questions to the relevant skills or qualities you have to demonstrate that you are fit for the job.</li>
                                <li>ust as you are getting hired to help the company, the company is also there to help you acquire certain skills.
                                    Examine the company’s suitability by asking them about the kind of training they provide.</li>
                                </ul>

                                <h2 className="section-heading" style={{fontSize: '32px', color: '#65c6ce'}}><strong>What should I do once the interview is over?</strong><br/></h2>
                                <ul>
                                <li>Send an email to thank the hiring manager for their time. This can leave a positive impression and make you more memorable. </li>
                                <li>If you get hired, be proactive and independent. Although you are only an intern, it is still important to take initiatives to increase your chances of scoring a permanent position. </li>
                                </ul>

                                <h2 className="section-heading" style={{fontSize: '32px', color: '#65c6ce'}}><strong>What should I do once the interview is over?</strong><br/></h2>
                                <ul>
                                <li>Send an email to thank the hiring manager for their time. This can leave a positive impression and make you more memorable. </li>
                                <li>Remind yourself that you can get through this and do not feel inadequate. There is a reason you were offered to do an interview. If the hiring manager believes in your potential, then you should too.  </li>
                                </ul>

                                

                                <p style={{marginTop: '32px'}}><span> <a style={{color: '#37c8cf'}} href="/signUp">Sign up</a> </span> today to experience local culture and practise English with native speakers.  </p>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        )
    }
}

export default PostEight;